import clsx from 'clsx'
import React from 'react'

export const PageTitle = ({
  title,
  containerClassName,
  titleClassName,
}: {
  title: string
  containerClassName?: string
  titleClassName?: string
}) => {

  return (
    <div className={clsx("md:flex md:items-center md:justify-between", containerClassName)}>
      <div className="min-w-0 flex-1">
        <h2 className={clsx("text-2xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight", titleClassName)}>
          {title}
        </h2>
      </div>
    </div>
  )
}
