import * as Popover from '@radix-ui/react-popover'
import React, { useState } from 'react'
import { Button } from './button'
import { disabledStatus } from './models/documentTypeMap'
import Status from './status'

const DocumentStatus = ({ info, docType }: { info: any; docType: string }) => {
  const [isOpen, setOpen] = useState(false)
  const docs = info.row.original.shipping_documents.filter((r: any) => r.type === docType)
  let isSameValidationStatus = docs?.every(
    ({ processing_result_status_code }: any) => processing_result_status_code === docs[0].processing_result_status_code
  )
  const val =
    docs?.length > 1 ? (!isSameValidationStatus ? 'Mixed' : docs[0]?.processing_result_status_code) : info.getValue()
  const Cell = ({ href, type }: { href?: any; type?: any }) => {
    const isDisabled = docs?.length > 1 || val === 'unreceived' || !val
    return <Status type={type} text={val} disabled={isDisabled} target="_blank" href={href} />
  }
  return docs.length > 1 ? (
    <div className="flex h-[24px] items-center">
      <Cell type={'Button'} />
      <Popover.Root onOpenChange={(e) => setOpen(e)}>
        <Popover.Trigger className="hover:bg-zinc-950/2 p-1">
          <Button plain>
            {isOpen ? (
              <img src="/static/images/down-icon.svg" alt="Down arrow" width={10} height={24} />
            ) : (
              <img src="/static/images/right-icon.svg" alt="Right arrow" width={8} height={8} />
            )}
          </Button>
        </Popover.Trigger>
        <Popover.Portal>
          <Popover.Content
            className="data-[state=open]:data-[side=top]:animate-slideDownAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade w-[350px] rounded bg-white p-5 shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] will-change-[transform,opacity] focus:shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2),0_0_0_2px_theme(colors.violet7)]"
            sideOffset={5}
          >
            <div className="max-h-[400px] max-w-sm overflow-hidden overflow-y-auto rounded bg-white">
              <div className="flex flex-col gap-y-2">
                {docs?.map((doc: any) => (
                  <div className="flex w-full items-center gap-x-5">
                    <div className="">
                      <Status
                        type="Link"
                        text={doc?.processing_result_status_code}
                        disabled={disabledStatus.includes(info.getValue() as string)}
                        target="_blank"
                        href={`/document/${doc?.id}?client-shipment-id=${info.row.original.client_shipment_id}`}
                      />
                    </div>
                    <p className="mr-5 block w-[100%] text-right text-sm/5">{doc?.id}</p>
                  </div>
                ))}
              </div>
            </div>
            <Popover.Arrow />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  ) : (
    <Cell href={`/document/${docs[0]?.id}?client-shipment-id=${info.row.original.client_shipment_id}`} />
  )
}

export default DocumentStatus
