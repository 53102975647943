import React from 'react'

export function DotsHorizontal(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.4 3.6C15.2837 3.6 16 2.88366 16 2C16 1.11635 15.2837 0.400002 14.4 0.400002C13.5163 0.400002 12.8 1.11635 12.8 2C12.8 2.88366 13.5163 3.6 14.4 3.6Z"
        fill="currentColor"
      />
      <path
        d="M8 3.6C8.88366 3.6 9.6 2.88366 9.6 2C9.6 1.11635 8.88366 0.400002 8 0.400002C7.11634 0.400002 6.4 1.11635 6.4 2C6.4 2.88366 7.11634 3.6 8 3.6Z"
        fill="currentColor"
      />
      <path
        d="M1.6 3.6C2.48366 3.6 3.2 2.88366 3.2 2C3.2 1.11635 2.48366 0.400002 1.6 0.400002C0.716344 0.400002 0 1.11635 0 2C0 2.88366 0.716344 3.6 1.6 3.6Z"
        fill="currentColor"
      />
    </svg>
  )
}
