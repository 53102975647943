import { Accordion } from '@szhsin/react-accordion'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AccordionItem } from 'shared/components/accordion-items'
import { Input } from 'shared/components/input'
import { Loader } from 'shared/components/loader'
import { Textarea } from 'shared/components/textarea'
import { FormLabels, FormsByType } from './model'
import type { IHighlight } from './react-pdf-highlighter'

interface Props {
  highlights: Array<IHighlight>
  resetHighlights: () => void
  toggleDocument: () => void
}

// const updateHash = (highlight: IHighlight) => {
//   document.location.hash = `highlight-${highlight.id}`;
// };

export function Sidebar({ highlights, toggleDocument, resetHighlights }: Props) {
  const document = useSelector((state: any) => state.documentDetailReducer)
  const [form, setForm] = useState<any>([])

  useEffect(() => {
    if (document.data) {
      const documentFields = FormsByType[document.data?.type as keyof typeof FormsByType]

      const formDetails: any = []
      documentFields?.forEach((field: any) => {
        if (!field.array_contains) {
          formDetails.push({
            title: field.name,
            fields: field.json_keys?.map((key: any) => {
              return {
                label: FormLabels?.[key as keyof typeof FormLabels],
                value: document.json_data.data?.[key],
              }
            }),
          })
        } else if (field.array_contains) {
          document.json_data.data?.[field.key]?.forEach((container: any, index: string) => {
            formDetails.push({
              title: `${field.name} ${index + 1}`,
              fields: Object.keys(container)?.map((_c) => ({
                label: FormLabels?.[_c as keyof typeof FormLabels],
                value: container[_c],
                element: field?.text_area_fields?.includes(_c) ? 'textarea' : 'text',
              })),
            })
          })
        }
      })
      setForm(formDetails)
    }
  }, [document.data, document.json_data.data])

  return (
    <div className="sidebar max-w-[50%] px-2" style={{ width: '50%' }}>
      <div className="ml-2 grid h-full grid-cols-12 gap-y-8">
        {!document?.json_data?.loader ? (
          <div className="col-span-12">
            <Accordion transition transitionTimeout={200} allowMultiple>
              {form?.map((data: any) => {
                return (
                  <AccordionItem header={data.title} initialEntered>
                    <div className="col-span-12">
                      <div className="mt-5 grid grid-cols-12 gap-x-6 gap-y-3">
                        {data?.fields?.map((field: any) => {
                          return (
                            <div className="col-span-4">
                              <label className="text-sm">{field.label}</label>
                              {field.element === 'textarea' ? (
                                <Textarea rows={3} value={field.value} disabled className="my-2" />
                              ) : (
                                <Input type="text" value={field.value} disabled className="my-2" />
                              )}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </AccordionItem>
                )
              })}
            </Accordion>
          </div>
        ) : (
          <div className="col-span-12">
            <Loader className="min-h-full" />
          </div>
        )}
      </div>
    </div>
  )
}
