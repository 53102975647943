import { Checkbox, Label } from 'flowbite-react'
import _ from 'lodash'
import React from 'react'
import { IFilterItem } from '.'

export default function FilterItemCheckBox({ data }: { data: IFilterItem }) {
  return (
    <>
      {data.options &&
        data.options.map((option, i) => (
          <div key={i} className="flex items-center">
            <Checkbox
              id={`${data.key}-${option.value}`}
              checked={data?.value?.includes(option.value)}
              name={`${data.key}-checkbox-${option.value}`}
              disabled={data.disabled}
              onChange={(e) => {
                if (e.target.checked) {
                  const newValue = _.uniq([...(data?.value || []), option.value])
                  data.onChange(newValue)
                } else {
                  data.onChange(data.value?.filter((v: string) => v !== option.value))
                }
              }}
            />
            <Label disabled={data.disabled} htmlFor={`${data.key}-${option.value}`} className="ml-2">
              {option.label}
            </Label>
          </div>
        ))}
    </>
  )
}
