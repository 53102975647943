import axios, { AxiosError, AxiosRequestConfig } from 'axios'

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL })

axiosInstance.interceptors.response.use(
  (res) => res,
  async (error) => interceptorsError(error)
)

export default axiosInstance
// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args]

  const res = await axiosInstance.get(url, { ...config })

  return res.data
}

const interceptorsError = async (error: AxiosError) => {
  const resError = (error.response && error.response) || 'Something went wrong'
  return typeof window === 'undefined' ? resError : resError
}
