import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'
import ActiveFiltersV2 from 'shared/components/active-filters-v2'
import { Button } from 'shared/components/button'
import DataTableV2 from 'shared/components/data-table-v2'
import { IFilterItem } from 'shared/components/filters-drawer'
import StatCard from 'shared/components/StatCard/StatCard'
import Tooltip from 'shared/components/tooltip'
import { useLocalStorage } from 'shared/hooks/useLocalStorage'
import { CalendarEditOutline } from 'shared/icons/CalendarEditOutline'
import { ClipboardListOutline } from 'shared/icons/ClipboardListOutline'
import { Clock } from 'shared/icons/Clock'
import { FileCheck } from 'shared/icons/FileCheck'
import { FileCheckOutline } from 'shared/icons/FileCheckOutline'
import { LayersOutLine } from 'shared/icons/LayersOutLine'
import { ITask } from 'shared/reduxStore/slices/tasks/types'
import { getDocumentTypeDisplayName, IFindManyTasksRequest, taskService } from 'shared/services'
import { REACT_QUERY_KEY } from 'shared/services/queryKey'
import {
  getDueDateColor,
  getDueDateText,
  getTaskStatus,
  getTaskStatusColor,
  getTaskTypeDisplayName,
  getTaskTypeOptions,
} from 'shared/services/tasks/tasks.utils'
import { twMerge } from 'tailwind-merge'

// Cache duration in milliseconds
const staleTime = 60 * 60 * 1000

export default function Tasks() {
  const orgId = useSelector((state: any) => state.authReducer.orgId)
  const defaultFilter: IFindManyTasksRequest = {
    org_id: orgId,
    category: ['freight_forwarder_task'],
    page_size: 10,
    page: 1,
    order_by: 'created_at',
    order_direction: 'desc',
  }

  const [searchParams, setSearchParams] = useSearchParams()

  const [filterStatus, setFilterStatus] = useState<string>('')

  const { state, update, reset } = useLocalStorage('active_task_id', {})

  const [filter, setFilter] = useState<IFindManyTasksRequest>(defaultFilter)
  const [applyFilter, setApplyFilter] = useState<IFindManyTasksRequest>(defaultFilter)

  const { data, isFetching } = useQuery({
    queryKey: [REACT_QUERY_KEY.GET_TASKS, { ...applyFilter }],
    queryFn: () => taskService.getTasks({ ...applyFilter }),
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime,
  })

  const { data: tempFilter } = useQuery({
    queryKey: [`${REACT_QUERY_KEY.GET_TASKS}_TEMP`, { ...filter }],
    queryFn: () => taskService.getTasks({ ...filter }),
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime,
  })

  const { data: stats, isFetching: isStatsFetching } = useQuery({
    queryKey: [REACT_QUERY_KEY.GET_TASK_STATS, { category: applyFilter.category, org_id: applyFilter.org_id }],
    queryFn: () => taskService.getTaskStats({ category: applyFilter.category, org_id: applyFilter.org_id }),
    refetchOnReconnect: 'always',
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime,
  })

  const [columns, setColumns] = useState([
    {
      accessorFn: (row: ITask) => row?.type || '',
      cell: (info: any) => {
        const type = info.getValue()
        const taskName = getTaskTypeDisplayName(type)
        return (
          <Link
            to={getTaskDetailsLink(info.row.original)}
            onClick={() => {
              update('id', info.row.original.id)
            }}
            className={twMerge([
              'cursor-pointer font-semibold capitalize text-gray-800 hover:underline',
              info.row.original.status === 'completed' && 'text-gray-400',
            ])}
          >
            {taskName}
          </Link>
        )
      },
      header: 'Task',
      size: 300,
      colName: 'Task',
      id: 'type',
      key: 'type',
    },
    {
      accessorFn: (row: ITask) => row?.status || '',
      cell: (info: any) => (
        <span
          className={twMerge([
            'flex items-center gap-1 font-inter text-sm font-normal capitalize',
            getTaskStatusColor(info.getValue()),
          ])}
        >
          {getTaskStatus(info.getValue())}
        </span>
      ),
      header: 'Task Status',
      size: 100,
      colName: 'Task Status',
      id: 'status',
      key: 'status',
    },
    {
      accessorFn: (row: ITask) => row?.created_at || '',
      cell: (info: any) => {
        const createdAt = new Date(info.getValue())
        return (
          <span
            className={twMerge([
              'flex items-center gap-1 font-inter text-sm font-normal',
              info.row.original.status === 'completed' && 'text-gray-400',
            ])}
          >
            {createdAt.toLocaleString()}
          </span>
        )
      },
      header: 'Creation Date',
      size: 200,
      colName: 'Creation Date',
      id: 'created_at',
      key: 'created_at',
    },
    {
      accessorFn: (row: ITask) => row?.schedule?.due_at || '',
      cell: (info: any) => {
        if (!info.getValue()) return null
        const dueDate = new Date(info.getValue())
        return (
          <span
            className={twMerge([
              'flex items-center gap-1 font-inter text-sm font-normal',
              getDueDateColor(dueDate),
              info.row.original.status === 'completed' && 'text-gray-400',
            ])}
          >
            <Tooltip
              tooltip={<span className="text-xs capitalize">{getDueDateText(dueDate, info.row.original.status)}</span>}
              className="bg-gray-800 font-medium text-white"
              arrowClassName="fill-gray-800"
              side="left"
              target={
                <span>
                  <Clock />
                </span>
              }
            />
            {dueDate.toLocaleString()}
          </span>
        )
      },
      header: 'Due Date',
      size: 100,
      colName: 'Due Date',
      id: 'schedule.due_at',
      key: 'schedule.due_at',
    },
    {
      accessorFn: (row: ITask) => row?.schedule?.completed_at || '',
      cell: (info: any) => {
        if (!info.getValue()) return null
        const completedAt = new Date(info.getValue())
        return (
          <span
            className={twMerge([
              'flex items-center gap-1 font-inter text-sm font-normal',
              info.row.original.status === 'completed' ? 'text-green-600' : 'text-gray-400',
            ])}
          >
            {completedAt.toLocaleString()}
          </span>
        )
      },
      header: 'Completion Date',
      size: 200,
      colName: 'Completion Date',
      id: 'schedule.completed_at',
      key: 'schedule.completed_at',
      initialVisibility: false,
      enableSorting: false,
    },
    {
      accessorFn: (row: ITask) => row?.data?.shipping_doc_type || '',
      cell: (info: any) => {
        const row = info.row.original
        const documentId = row?.data?.shipping_document_id
        const documentName = getDocumentTypeDisplayName(info.getValue())
        if (!documentName) return null
        return (
          <Link to={documentId ? `/document/${documentId}` : '#'} target="_blank" rel="noreferrer noopener">
            <span className="inline-flex items-center gap-1 rounded-lg border border-green-600 px-3 py-1 uppercase text-green-600">
              <FileCheck />
              {documentName || 'UNKNOWN'}
            </span>
          </Link>
        )
      },
      meta: {
        cellStyle: {
          textOverflow: 'unset',
        },
      },
      header: 'Document',
      size: 200,
      colName: 'Document',
      id: 'data.shipping_doc_type',
      key: 'data.shipping_doc_type',
      enableSorting: false,
    },
    {
      accessorFn: (row: ITask) => row?.data?.client_shipment_id || '',
      cell: (info: any) => {
        const clientShipmentId = info.getValue()
        const shipmentId = info.row.original?.data?.shipment_ids?.[0]
        return (
          <Link to={shipmentId ? `/shipments/${shipmentId}` : '#'} target="_blank" rel="noreferrer noopener">
            <span
              className={twMerge([
                'flex items-center gap-1 font-inter text-sm font-normal hover:underline',
                info.row.original.status === 'completed' && 'text-gray-400',
              ])}
            >
              {clientShipmentId}
            </span>
          </Link>
        )
      },
      header: 'Client Shipment ID',
      size: 100,
      colName: 'Client Shipment ID',
      id: 'data.client_shipment_id',
      key: 'data.client_shipment_id',
      enableSorting: false,
    },
  ])

  const getTaskDetailsLink = (task: ITask) => {
    const routeMap: { [key: string]: string } = {
      received_corrupt_document: `/task/corrupt-document/${task.id}`,
      unassigned_shipment: `/task/unassigned/${task.id}`,
      received_duplicate_document: `/task/duplicate/${task.id}`,
      shipment_data_discrepancy: `/task/discrepancy/${task.id}`,
      unregistered_party_address: `/task/missing-org-data/${task.id}`,
    }
    return routeMap[task.type] || ''
  }

  const filtersCount = (filter: IFindManyTasksRequest) => {
    let count = 0
    if (filter.urgency && filter.urgency.length > 0) count++
    if (filter.status && filter.status.length > 0) count++
    if (filter.type && filter.type.length > 0) count++
    if (filter.shipping_doc_type && filter.shipping_doc_type.length > 0) count++
    if (filter.created_at__gte || filter.created_at__lte) count++
    if (filter.due_date__gte || filter.due_date__lte) count++
    if (filter.completed_at__gte || filter.completed_at__lte) count++
    return count
  }

  const filtersApplyCount = (filter: IFindManyTasksRequest) => {
    let count = 0
    if (filter.urgency && filter.urgency.length > 0) count++
    if (filter.status && filter.status.length > 0) count++
    if (filter.type && filter.type.length > 0) count++
    if (filter.shipping_doc_type && filter.shipping_doc_type.length > 0) count++
    if (filter.created_at__gte || filter.created_at__lte) count++
    if (filter.due_date__gte || filter.due_date__lte) count++
    if (filter.completed_at__gte || filter.completed_at__lte) count++
    return count
  }

  const resetFilter = () => {
    setFilter(defaultFilter)
    handleApplyFilter(defaultFilter)
    setFilterStatus('')
  }

  const handleClearFilter = (key: string, value: any) => {
    setFilterStatus('')
    setFilter((prev) => ({
      ...prev,
      page: 1,
      [key]: value,
    }))
    handleApplyFilter({
      ...applyFilter,
      page: 1,
      [key]: value,
    })
  }

  const onToggleCard = (value: string) => {
    if (filterStatus !== value) {
      if (value === 'open') {
        setFilter({
          ...defaultFilter,
          status: ['assigned'],
        })
        handleApplyFilter({
          ...defaultFilter,
          status: ['assigned'],
        })
        setFilterStatus(value)
      } else if (value === 'due_by_eod') {
        setFilter({
          ...defaultFilter,
          due_date__gte: moment().format('YYYY-MM-DD'),
          due_date__lte: moment().format('YYYY-MM-DD'),
          status: ['assigned'],
        })
        handleApplyFilter({
          ...defaultFilter,
          due_date__gte: moment().format('YYYY-MM-DD'),
          due_date__lte: moment().format('YYYY-MM-DD'),
          status: ['assigned'],
        })
        setFilterStatus(value)
      } else if (value === 'overdue') {
        setFilter({
          ...defaultFilter,
          urgency: ['overdue'],
          status: ['assigned'],
        })
        handleApplyFilter({
          ...defaultFilter,
          urgency: ['overdue'],
          status: ['assigned'],
        })
        setFilterStatus(value)
      } else {
        setFilter({
          ...defaultFilter,
          urgency: value === 'all' ? undefined : [value],
        })
        handleApplyFilter({
          ...defaultFilter,
          urgency: value === 'all' ? undefined : [value],
        })
        setFilterStatus(value)
      }
    } else {
      setFilter({
        ...defaultFilter,
      })
      handleApplyFilter({
        ...defaultFilter,
        urgency: undefined,
      })
      setFilterStatus('')
    }
  }

  const handleApplyFilter = (_filter: IFindManyTasksRequest) => {
    const params = new URLSearchParams()
    const newFilter = { ..._filter }
    if (newFilter.page) {
      params.set('page', newFilter.page.toString())
    } else {
      params.set('page', '1')
    }
    if (newFilter.page_size) {
      params.set('page_size', newFilter.page_size.toString())
    }
    if (newFilter.order_by) {
      params.set('order_by', newFilter.order_by)
    }
    if (newFilter.order_direction) {
      params.set('order_direction', newFilter.order_direction)
    }

    if (newFilter.urgency && newFilter.urgency.length > 0) {
      params.set('urgency', newFilter.urgency.join(','))
    }
    if (newFilter.status && newFilter.status.length > 0) {
      params.set('status', newFilter.status.join(','))
    }
    if (newFilter.type && newFilter.type.length > 0) {
      params.set('type', newFilter.type.join(','))
    }
    if (newFilter.shipping_doc_type && newFilter.shipping_doc_type.length > 0) {
      params.set('shipping_doc_type', newFilter.shipping_doc_type.join(','))
    }
    if (newFilter.created_at__gte) {
      params.set('created_at__gte', newFilter.created_at__gte)
    }
    if (newFilter.created_at__lte) {
      params.set('created_at__lte', newFilter.created_at__lte)
    }
    if (newFilter.due_date__gte) {
      params.set('due_date__gte', newFilter.due_date__gte)
    }
    if (newFilter.due_date__lte) {
      params.set('due_date__lte', newFilter.due_date__lte)
    }
    if (newFilter.completed_at__gte) {
      params.set('completed_at__gte', newFilter.completed_at__gte)
    }
    if (newFilter.completed_at__lte) {
      params.set('completed_at__lte', newFilter.completed_at__lte)
    }
    setSearchParams(params)
  }

  useEffect(() => {
    const page = searchParams.get('page')
    const page_size = searchParams.get('page_size')
    const order_by = searchParams.get('order_by')
    const order_direction = searchParams.get('order_direction')
    const urgency = searchParams.get('urgency')
    const status = searchParams.get('status')
    const type = searchParams.get('type')
    const shipping_doc_type = searchParams.get('shipping_doc_type')
    const created_at__gte = searchParams.get('created_at__gte')
    const created_at__lte = searchParams.get('created_at__lte')
    const due_date__gte = searchParams.get('due_date__gte')
    const due_date__lte = searchParams.get('due_date__lte')
    const completed_at__gte = searchParams.get('completed_at__gte')
    const completed_at__lte = searchParams.get('completed_at__lte')

    const newFilter = {
      page: page ? parseInt(page) : 1,
      page_size: page_size ? parseInt(page_size) : 10,
      order_by: order_by || 'created_at',
      order_direction: order_direction || 'desc',
      urgency: urgency ? urgency.split(',') : undefined,
      status: status ? status.split(',') : undefined,
      type: type ? type.split(',') : undefined,
      shipping_doc_type: shipping_doc_type ? shipping_doc_type.split(',') : undefined,
      created_at__gte: created_at__gte || undefined,
      created_at__lte: created_at__lte || undefined,
      due_date__gte: due_date__gte || undefined,
      due_date__lte: due_date__lte || undefined,
      completed_at__gte: completed_at__gte || undefined,
      completed_at__lte: completed_at__lte || undefined,
    }
    setApplyFilter((prev) => ({ ...prev, ...newFilter }))
    setFilter((prev) => ({ ...prev, ...newFilter }))
  }, [searchParams])

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      reset()
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [reset])

  const filterItems: IFilterItem[] = [
    {
      key: 'urgency',
      title: 'Task Urgency',
      options: [
        { label: 'Overdue', value: 'overdue' },
        { label: 'Due Soon', value: 'due_soon' },
        { label: 'Upcoming', value: 'upcoming' },
      ],
      onChange: (value: string[]) => {
        setFilterStatus('')
        setFilter((prev) => ({
          ...prev,
          page: 1,
          status: ['assigned'],
          urgency: value,
        }))
      },
      value: filter.urgency || [],
      activeValue: applyFilter.urgency || [],
      type: 'checkbox',
      onClear: () => {
        handleClearFilter('urgency', undefined)
      },
      disabled: filtersCount(filter) > 0 && (!filter.urgency || filter.urgency.length === 0),
    },
    {
      key: 'status',
      title: 'Task Status',
      options: [
        { label: 'Open', value: 'assigned' },
        { label: 'Completed', value: 'completed' },
      ],
      onChange: (value: string[]) => {
        setFilterStatus('')
        setFilter((prev) => ({
          ...prev,
          page: 1,
          status: value,
        }))
      },
      onClear: () => {
        handleClearFilter('status', undefined)
      },
      value: filter.status,
      activeValue: applyFilter.status || '',
      type: 'checkbox',
      disabled:
        (filtersCount(filter) > 0 && (!filter.status || filter.status.length === 0)) ||
        (filter?.urgency && filter?.urgency?.length > 0),
    },
    {
      key: 'type',
      title: 'Task Type',
      type: 'dropdown',
      options: getTaskTypeOptions(),
      onChange: (value: string[]) => {
        setFilterStatus('')
        setFilter((prev) => ({
          ...prev,
          page: 1,
          type: value,
        }))
      },
      onClear: () => {
        handleClearFilter('type', [])
      },
      value: filter.type || [],
      activeValue: applyFilter.type || [],
      placeholder: 'Search Task Type',
      disabled: filtersCount(filter) > 0 && (!filter.type || filter.type.length === 0),
    },
    {
      key: 'created_at',
      title: 'Creation Date',
      type: 'dateRange',
      value: [filter.created_at__gte, filter.created_at__lte],
      onChange: (value: Date[]) => {
        setFilterStatus('')
        setFilter((prev) => ({
          ...prev,
          page: 1,
          created_at__gte: value[0] ? moment(value[0]).format('YYYY-MM-DD') : undefined,
          created_at__lte: value[1] ? moment(value[1]).format('YYYY-MM-DD') : undefined,
        }))
      },
      onClear: () => {
        setFilterStatus('')
        setFilter((prev) => ({
          ...prev,
          page: 1,
          created_at__gte: undefined,
          created_at__lte: undefined,
        }))
        handleApplyFilter({
          ...applyFilter,
          page: 1,
          created_at__gte: undefined,
          created_at__lte: undefined,
        })
      },
      activeValue: [applyFilter.created_at__gte, applyFilter.created_at__lte],
      placeholder: 'Select Date',
      disabled: filtersCount(filter) > 0 && !filter.created_at__gte && !filter.created_at__lte,
    },
    {
      key: 'due_date',
      title: 'Due Date',
      type: 'dateRange',
      value: [filter.due_date__gte, filter.due_date__lte],
      onChange: (value: Date[]) => {
        setFilterStatus('')
        setFilter((prev) => ({
          ...prev,
          page: 1,
          due_date__gte: value[0] ? moment(value[0]).format('YYYY-MM-DD') : undefined,
          due_date__lte: value[1] ? moment(value[1]).format('YYYY-MM-DD') : undefined,
        }))
      },
      onClear: () => {
        setFilterStatus('')
        setFilter((prev) => ({
          ...prev,
          page: 1,
          due_date__gte: undefined,
          due_date__lte: undefined,
        }))
        handleApplyFilter({
          ...applyFilter,
          page: 1,
          due_date__gte: undefined,
          due_date__lte: undefined,
        })
      },
      activeValue: [applyFilter.due_date__gte, applyFilter.due_date__lte],
      placeholder: 'Select Date',
      disabled: filtersCount(filter) > 0 && !filter.due_date__gte && !filter.due_date__lte,
    },
    {
      key: 'completed_at',
      title: 'Completion Date',
      type: 'dateRange',
      value: [filter.completed_at__gte, filter.completed_at__lte],
      onChange: (value: Date[]) => {
        setFilterStatus('')
        setFilter((prev) => ({
          ...prev,
          page: 1,
          completed_at__gte: value[0] ? moment(value[0]).format('YYYY-MM-DD') : undefined,
          completed_at__lte: value[1] ? moment(value[1]).format('YYYY-MM-DD') : undefined,
        }))
      },
      onClear: () => {
        setFilterStatus('')
        setFilter((prev) => ({
          ...prev,
          page: 1,
          completed_at__gte: undefined,
          completed_at__lte: undefined,
        }))
        handleApplyFilter({
          ...applyFilter,
          page: 1,
          completed_at__gte: undefined,
          completed_at__lte: undefined,
        })
      },
      activeValue: [applyFilter.completed_at__gte, applyFilter.completed_at__lte],
      placeholder: 'Select Date',
      disabled: filtersCount(filter) > 0 && !filter.completed_at__gte && !filter.completed_at__lte,
    },
  ]

  const totalPages = data?.total ? Math.ceil(data.total / (applyFilter.page_size || 10)) : 0

  return (
    <main>
      <div className="grid grid-cols-2 gap-6 md:grid-cols-4">
        <StatCard
          icon={<LayersOutLine />}
          loading={isStatsFetching}
          text="All Tasks"
          count={stats?.total || 0}
          active={filterStatus === 'all'}
          onClick={onToggleCard.bind(null, 'all')}
        />
        <StatCard
          icon={<CalendarEditOutline />}
          loading={isStatsFetching}
          text="Overdue Tasks"
          count={stats?.overdue || 0}
          active={filterStatus === 'overdue'}
          onClick={onToggleCard.bind(null, 'overdue')}
        />
        <StatCard
          icon={<ClipboardListOutline />}
          loading={isStatsFetching}
          text="Tasks Due by EOD"
          count={stats?.dueByEOD || 0}
          active={filterStatus === 'due_by_eod'}
          onClick={onToggleCard.bind(null, 'due_by_eod')}
        />
        <StatCard
          icon={<FileCheckOutline />}
          loading={isStatsFetching}
          text="Open Tasks"
          count={stats?.open || 0}
          active={filterStatus === 'open'}
          onClick={onToggleCard.bind(null, 'open')}
        />
      </div>
      <DataTableV2
        tableId="tasks-list"
        title="Tasks"
        className="mt-6"
        columns={columns}
        setColumns={setColumns}
        data={data?.tasks || []}
        loading={isFetching}
        totalPages={totalPages}
        currentPage={applyFilter.page || 1}
        totalCount={data?.total || 0}
        //
        filter={filter}
        setFilter={setFilter}
        applyFilter={applyFilter}
        handleApply={(filter) => {
          if (!filter.page) {
            setFilterStatus('')
          }
          handleApplyFilter({
            ...applyFilter,
            ...filter,
            ...(!filter.page && { page: 1 }),
          })
        }}
        filterCount={filtersApplyCount(applyFilter)}
        actions={[]}
        filterItems={filterItems}
        totalFilterResults={filtersCount(filter) && tempFilter?.total ? tempFilter.total : 0}
        resetFilter={resetFilter}
        activeFilters={
          <ActiveFiltersV2
            filterItems={filterItems}
            filters={filter}
            handleApply={(filter) => {
              handleApplyFilter({
                ...applyFilter,
                ...filter,
              })
            }}
            resetFilter={resetFilter}
          />
        }
        emptyContent={{
          title: filtersApplyCount(applyFilter) ? 'Result Not Found' : 'No Tasks Available',
          description: filtersApplyCount(applyFilter)
            ? 'Your search did not return any results.\nPlease try refining your search criteria.'
            : 'There are currently no tasks assigned. Please check back later or explore other sections.',
          action: filtersApplyCount(applyFilter) ? (
            <Button onClick={resetFilter} className="!px-8">
              Clear
            </Button>
          ) : undefined,
        }}
        filterAlert={
          filtersCount(filter) > 0
            ? {
                color: 'info',
                message: 'Only one filter can be used at a time.',
              }
            : undefined
        }
        activeRows={[state.id]}
      />
    </main>
  )
}
