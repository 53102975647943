import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PaginatedQueryRequest } from '../firebase/model'
import { documentStats, fetchDocuments } from '../firebase/query/document'
import {
  updateDocsList,
  updateDocsListStats,
  updateDocsListStatsLoading,
  updateLoader,
} from '../reduxStore/slices/documents/documentsSlice'
import { AppDispatch } from '../reduxStore/store'

const useDocuments = (request: PaginatedQueryRequest) => {
  const orgId = useSelector((state: any) => state.authReducer.orgId)

  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    dispatch(updateLoader())
    const documents = fetchDocuments({
      ...request,
      orgId,
    }, (payload: any) => {
      dispatch(updateDocsList(payload))
    })
    return () => documents()
  }, [request, orgId, dispatch])

  useEffect(() => {
    dispatch(updateDocsListStatsLoading())
    const docStats = documentStats({
      ...request,
      orgId,
    }, (payload: any) => dispatch(updateDocsListStats(payload)))
    return () => docStats()
  }, [dispatch, request, orgId])
}

export default useDocuments
