import moment from 'moment'
import React from 'react'
import DatePicker from 'react-datepicker'
import { FaCalendarAlt } from 'react-icons/fa'
import { IFilterItem } from '.'

import 'react-datepicker/dist/react-datepicker.css'

export default function FilterItemDateRange({ data }: { data: IFilterItem }) {
  return (
    <div className="flex w-full items-center gap-2">
      <div className="relative">
        <DatePicker
          showIcon
          icon={<FaCalendarAlt />}
          selected={data.value[0] ? moment(data.value[0]).toDate() : null}
          onChange={(date) => {
            data.onChange([date, data.value[1] || null])
          }}
          disabled={data.disabled}
          placeholderText={data.placeholder || 'Start Date'}
          dateFormat="MM/dd/yyyy"
          className="w-full"
          isClearable
          popperPlacement="bottom-start"
          maxDate={data.value[1] ? moment(data.value[1]).toDate() : undefined}
        />
      </div>
      <span>to</span>
      <div className="relative">
        <DatePicker
          showIcon
          icon={<FaCalendarAlt />}
          className="w-full"
          disabled={data.disabled}
          selected={data.value[1] ? moment(data.value[1]).toDate() : null}
          onChange={(date) => {
            data.onChange([data.value[0] || null, date])
          }}
          isClearable
          popperPlacement="bottom-start"
          placeholderText={data.placeholder || 'End Date'}
          dateFormat="MM/dd/yyyy"
          minDate={data.value[0] ? moment(data.value[0]).toDate() : undefined}
        />
      </div>
    </div>
  )
}
