import React, { ReactNode } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import { Route, Routes } from 'react-router-dom'
import { DefaultDashboardNavbar } from 'shared/components/Navigation'
import { Spinner } from 'shared/components/Spinner'
import { useAuthenticate } from 'shared/hooks/useAuthenticate'
import './App.css'
import DocumentDetails from './containers/Document/details'
import Document from './containers/Document/document'
import ShipmentDetails from './containers/Shipments/Details/ShipmentDetails'
import Shipments from './containers/Shipments/shipments'
import TaskDetails from './containers/Taskdetails/TaskDetails'
import TaskDiscrepancy from './containers/Taskdetails/TaskDiscrepancy'
import TaskDetailsDuplicate from './containers/Taskdetails/TaskDuplicate'
import TaskMissingOrgData from './containers/Taskdetails/TaskMissingOrgData'
import TaskDetailsUnassigned from './containers/Taskdetails/TaskUnassigned'
import Tasks from './containers/Tasks/tasks'
import Login2 from './shared/containers/Login/login-2'

interface MainLayoutProps {
  children: ReactNode
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  return (
    <div className="min-h-screen">
      <DefaultDashboardNavbar prefix="" />
      <div className="content container mx-auto py-6">{children}</div>
    </div>
  )
}

const AppLayout: React.FC = () => {
  return (
    <div className="min-h-screen">
      <Routes>
        {/* Routes that should show the navbar */}
        <Route
          path="/shipments"
          element={
            <MainLayout>
              <Shipments />
            </MainLayout>
          }
        />
        <Route
          path="/"
          element={
            <MainLayout>
              <h1>Dashboard</h1>
            </MainLayout>
          }
        />
        <Route
          path="/dashboard"
          element={
            <MainLayout>
              <h1>Dashboard</h1>
            </MainLayout>
          }
        />
        <Route
          path="/documents"
          element={
            <MainLayout>
              <Document />
            </MainLayout>
          }
        />
        <Route
          path="/tasks"
          element={
            <MainLayout>
              <Tasks />
            </MainLayout>
          }
        />
        <Route
          path="/shipments/:shipmentId"
          element={
            <MainLayout>
              <ShipmentDetails />
            </MainLayout>
          }
        />
        <Route
          path="/document/:docId"
          element={
            <MainLayout>
              <DocumentDetails />
            </MainLayout>
          }
        />

        {/* Route that should not show the navbar */}
        <Route path="/task/corrupt-document/:taskId" element={<TaskDetails />} />
        <Route path="/task/unassigned/:taskId" element={<TaskDetailsUnassigned />} />
        <Route path="/task/duplicate/:taskId" element={<TaskDetailsDuplicate />} />
        <Route path="/task/missing-org-data/:taskId" element={<TaskMissingOrgData />} />
        <Route path="/task/discrepancy/:taskId" element={<TaskDiscrepancy />} />
      </Routes>
    </div>
  )
}

function App() {
  const isAuthenticated = useAuthenticate('/tasks')
  if (!isAuthenticated) return <Spinner />

  return (
    <Routes>
      {/* Login route */}
      <Route path="/login" element={<Login2 />} />
      <Route path="/*" element={<AppLayout />} />
    </Routes>
  )
}

export default App
