import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import { Button } from './button'
import { IFilterItem } from './filters-drawer'
import FilterItemCheckBox from './filters-drawer/item-checkbox'
import FilterItemDateRange from './filters-drawer/item-date-range'
import FilterItemDropdown from './filters-drawer/item-dropdown'
import FilterItemRadioCheckbox from './filters-drawer/item-radio-checkbox'
import FilterItemSearchInput from './filters-drawer/item-search-input'
interface IActiveFilters {
  filters: any
  filterItems: IFilterItem[]
  handleApply: (data: any) => void
  resetFilter: () => void
}

const ActiveFiltersV2 = ({ filters, filterItems, resetFilter, handleApply }: IActiveFilters) => {
  const activeFilters = filterItems.filter((item) => {
    if (!item.activeValue) return false
    if (item.type === 'dateRange' && item.activeValue?.filter((v: any) => v).length === 0) return false
    if (Array.isArray(item.activeValue) && item.activeValue.length === 0) return false
    return true
  })

  if (activeFilters.length === 0) return null

  return (
    <div className="flex flex-wrap gap-x-2 gap-y-2 px-3 pb-1 pt-3">
      {filterItems.map((item, i) => {
        if (!item.activeValue) return null
        const isArray = Array.isArray(item.activeValue)
        if (isArray && item.activeValue.length === 0) return null
        if (item.type === 'dateRange' && item.activeValue?.filter((v: any) => v).length === 0) return null
        let displayValue = ''
        if (typeof item.activeValue === 'string') displayValue = item.activeValue
        if (isArray) {
          displayValue =
            item.activeValue?.length > 1
              ? item.activeValue?.length.toString()
              : item.options?.find((o) => o.value === item.activeValue[0])?.label || item.activeValue[0]
        }

        return (
          <Popover key={`filter-${i}`} className="relative">
            <PopoverButton className="border-secondary-border flex items-center rounded-lg border bg-white px-4 py-2 text-sm">
              <p>
                {item.type === 'dateRange' ? (
                  <>
                    {item.title}{' '}
                    {item.activeValue[0] === item.activeValue[1] ? (
                      <span>
                        :{' '}
                        <strong className="font-semibold">{moment(item.activeValue[0]).format('MMM DD, YYYY')}</strong>
                      </span>
                    ) : (
                      <span className="capitalize">
                        {item.activeValue[0] ? (
                          <span>
                            From:{' '}
                            <strong className="font-semibold">
                              {moment(item.activeValue[0]).format('MMM DD, YYYY')}
                            </strong>
                          </span>
                        ) : (
                          ''
                        )}
                        {item.activeValue[1] ? (
                          <span>
                            &nbsp;To:{' '}
                            <strong className="font-semibold">
                              {moment(item.activeValue[1]).format('MMM DD, YYYY')}
                            </strong>
                          </span>
                        ) : (
                          ''
                        )}
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    {item.title}:{' '}
                    <strong className="font-semibold capitalize">{displayValue ? displayValue : ''}</strong>
                  </>
                )}
                &nbsp;&nbsp;
              </p>
              <span
                className="hover: cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation()
                  item.onClear?.()
                }}
              >
                <XMarkIcon height={15} width={15} />
              </span>
            </PopoverButton>
            <PopoverPanel
              transition
              anchor="bottom"
              className="min-w-48 !overflow-visible rounded-xl border-gray-200 bg-white text-sm shadow-md transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
            >
              {({ close }) => (
                <>
                  <div className="flex flex-col gap-2 p-3">
                    {item.type === 'checkbox' && <FilterItemCheckBox data={item} />}
                    {item.type === 'inputSearch' && <FilterItemSearchInput data={item} />}
                    {item.type === 'dateRange' && <FilterItemDateRange data={item} />}
                    {item.type === 'radio' && <FilterItemRadioCheckbox data={item} />}
                    {item.type === 'dropdown' && (
                      <div className="flex w-full items-center gap-2">
                        <FilterItemDropdown data={item} hasCombobox={false} />
                      </div>
                    )}
                  </div>
                  <Button
                    className="w-full rounded-b-lg rounded-t-none"
                    onClick={() => {
                      handleApply(filters)
                      close()
                    }}
                  >
                    Apply Filters
                  </Button>
                </>
              )}
            </PopoverPanel>
          </Popover>
        )
      })}
      {activeFilters.length > 0 && (
        <button
          onClick={resetFilter}
          className={twMerge([
            'border-secondary-border flex items-center rounded-lg border bg-white px-4 py-2 text-sm',
            'hover:bg-gray-100',
          ])}
        >
          Reset Filter
        </button>
      )}
    </div>
  )
}
export default ActiveFiltersV2
