import React, { useEffect, useRef, useState } from 'react'

import { EnvelopeIcon } from '@heroicons/react/24/outline'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Breadcrumb } from 'shared/components/breadcrumb'
import { Button } from 'shared/components/button'
import { Divider } from 'shared/components/divider'
import { Loader } from 'shared/components/loader'
import { DocumentType } from 'shared/components/models/documentTypeMap'
import Tooltip from 'shared/components/tooltip'
import { getGSReference } from 'shared/firebase/utils'
import useDocumentDetails from 'shared/hooks/useDocumentDetails'
import { toggleSidebar } from 'shared/reduxStore'
import { getDocumentData, updateDocSrc } from 'shared/reduxStore/slices/document/documentDetailsSlice'
import { AppDispatch } from 'shared/reduxStore/store'
import DocumentDetailsCom from './Details/App'
import { EmailModal } from './Details/email-modal'

export default function DocumentDetails() {
  const urlParams = new URLSearchParams(`?${window.location.href?.split('?')[1]}`)
  const clientShipmentId = urlParams.get('client-shipment-id')
  const emailSubjectRef = useRef<HTMLParagraphElement>(null)
  const [emailSubjectEllipsisActive, setEmailSubjectEllipsisActive] = useState(false)

  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    dispatch(toggleSidebar({ isExpanded: false }))
  }, [dispatch])

  useEffect(() => {
    if (emailSubjectRef?.current) {
      setEmailSubjectEllipsisActive(
        emailSubjectRef?.current?.offsetWidth < emailSubjectRef?.current?.scrollWidth ? true : false
      )
    }
  }, [])

  const { docId } = useParams()

  useDocumentDetails({
    docId: docId || '',
    orgId: '',
  })

  const document = useSelector((state: any) => state.documentDetailReducer)

  useEffect(() => {
    if (document.data) {
      getGSReference(document.data.structured_payload_gs_link, (url: string) => dispatch(getDocumentData(url)))
      const doc = document.data.original_payload_gs_link.includes('xlsx')
        ? document.data.transformed_payload_gs_link
        : document.data.original_payload_gs_link
      getGSReference(doc, (url: string) => dispatch(updateDocSrc(url)))
    }
  }, [dispatch, document.data])

  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false)
  const [isCopied, setIsCopied] = useState(false)

  const copyMailSubject = (text: string) => {
    navigator.clipboard.writeText(text)
    setIsCopied(true)
    setTimeout(() => {
      setIsCopied(false)
    }, 1000)
  }

  return !document.loader ? (
    <>
      <Breadcrumb
        pages={
          clientShipmentId
            ? [
                {
                  name: 'Shipments',
                  href: '/shipments',
                  current: false,
                },
                {
                  name: `Shipment #${clientShipmentId || ''}`,
                  href: `/shipments/${document?.data?.shipment_ids?.[0]}`,
                  current: false,
                },
                {
                  name: 'Document details',
                  href: '#',
                  current: true,
                },
              ]
            : [
                {
                  name: 'Documents',
                  href: '/documents',
                  current: false,
                },
                {
                  name: 'Document details',
                  href: '#',
                  current: true,
                },
              ]
        }
      />
      <div className="my-4 flex flex-wrap items-center gap-x-3">
        <p>
          <strong>File name:</strong>&nbsp;{document.data?.metadata?.received_email_doc_filename}
        </p>
        <p>
          <strong>Document type:</strong>&nbsp;{DocumentType[document.data?.type as keyof typeof DocumentType]}
        </p>
        <p className="max-w-[400px]">
          <strong>Time of upload:</strong>&nbsp;{moment(document.data?.created_at).format('MM/DD/YYYY HH:mm:ss')}
        </p>
        <Tooltip
          tooltip={emailSubjectEllipsisActive ? document.data?.metadata?.received_email_subject : ''}
          side="bottom"
          target={
            <p
              className={`w-[${emailSubjectEllipsisActive ? '600px' : '300px'}] basis-[${emailSubjectEllipsisActive ? '600px' : '300px'}] cursor-pointer truncate whitespace-nowrap`}
              ref={emailSubjectRef}
            >
              <strong>Email subject:</strong>&nbsp;{document.data?.metadata?.received_email_subject}
            </p>
          }
        />
        <Button
          plain
          className="!m-0 h-[18px] w-[18px] !p-0"
          onClick={() => copyMailSubject(document.data?.metadata?.received_email_subject)}
        >
          <img src="/static/images/copy.svg" alt="" />
        </Button>
        {isCopied ? <p className="text-green-500">Copied!</p> : null}
        <Tooltip
          tooltip="Click to open email body"
          side="bottom"
          target={
            <Button plain className="!m-0 h-[18px] w-[18px] !p-0" onClick={() => setIsEmailModalOpen(true)}>
              <EnvelopeIcon className="!m-0" />
            </Button>
          }
        />
      </div>
      <Divider />
      <div className="mt-5 grid grid-cols-12">
        <div className="col-span-6">
          <h3 className="mb-4 text-left font-semibold">Document view</h3>
        </div>
        <div className="col-span-6">
          <h3 className="mb-4 text-left font-semibold">Digitalized view</h3>
        </div>
      </div>
      <DocumentDetailsCom />
      <EmailModal isOpen={isEmailModalOpen} setIsOpen={setIsEmailModalOpen} />
    </>
  ) : (
    <Loader className="!h-[100vh]" />
  )
}
