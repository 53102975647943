import axios from '../axios'

import { handleApiResponse } from '../utils'
import { IFindManyTasksRequest, IFindManyTasksResponse, ITaskStatsResponse } from './tasks.types'

const servicePath = `/tasks`

// Note: Importantly, no export endpoints here
// All API call in service

const endpoints = {
  getTasks: {
    url: `${servicePath}`,
    method: 'GET',
  },
  getTaskStats: {
    url: `${servicePath}/stats`,
    method: 'GET',
  },
  resolveTask: {
    url: `${servicePath}/:id/resolve`,
    method: 'PATCH',
  },
}

export class TaskServiceClass {
  private _client = axios

  public async getTasks(params?: IFindManyTasksRequest): Promise<IFindManyTasksResponse> {
    const res = await this._client.request({
      url: endpoints.getTasks.url,
      method: endpoints.getTasks.method,
      params: {
        ...params,
        ...(params?.page && { page: params.page }),
        ...(params?.page_size && { page_size: params.page_size }),
        ...(params?.order_by && {
          order_by: params.order_by === 'time_in_queue' ? 'schedule.due_at' : params.order_by,
        }),
        ...(params?.order_direction && { order_direction: params.order_direction }),
        ...(params?.category && { category: params.category.join(',') }),
        ...(params?.status && { status: params.status.join(',') }),
        ...(params?.type && { type: params.type.join(',') }),
        ...(params?.urgency && { urgency: params.urgency.join(',') }),
        ...(params?.shipping_doc_type && { shipping_doc_type: params.shipping_doc_type.join(',') }),
      },
    })

    return handleApiResponse<IFindManyTasksResponse>(res)
  }

  public async getTaskStats(params?: IFindManyTasksRequest): Promise<ITaskStatsResponse> {
    const res = await this._client.request({
      url: endpoints.getTaskStats.url,
      method: endpoints.getTaskStats.method,
      params: {
        ...params,
        ...(params?.org_id && { org_id: params.org_id }),
        ...(params?.category && { category: params.category.join(',') }),
        ...(params?.status && { status: params.status.join(',') }),
        ...(params?.type && { type: params.type.join(',') }),
        ...(params?.urgency && { urgency: params.urgency.join(',') }),
        ...(params?.shipping_doc_type && { shipping_doc_type: params.shipping_doc_type.join(',') }),
      },
    })

    return handleApiResponse<ITaskStatsResponse>(res)
  }

  public async resolveTask(data: { id: string; version: number; result: Record<string, any> }): Promise<string> {
    const res = await this._client.request({
      url: endpoints.resolveTask.url.replace(':id', data.id),
      method: endpoints.resolveTask.method,
      data,
    })

    return handleApiResponse<string>(res)
  }
}

export const taskService = new TaskServiceClass()
