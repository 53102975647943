import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { twMerge } from 'tailwind-merge'

interface StatCardProps {
  icon: React.ReactNode
  text: string
  count: number
  loading?: boolean
  active?: boolean
  onClick?: () => void
}

const StatCard: React.FC<StatCardProps> = ({ icon, text, count, loading, active, onClick }) => {
  return (
    <div
      className={twMerge([
        'cursor-pointer rounded-lg border border-gray-300 bg-white p-4 hover:bg-gray-300',
        active ? 'bg-gray-300' : '',
      ])}
      onClick={onClick}
    >
      <div className="flex-shrink-0 text-gray-400">{icon}</div>
      <div className="mt-1">
        <p className="text-md font-medium text-gray-500">{text}</p>
        {!loading ? (
          <p className="mt-2 text-3xl font-semibold text-gray-900">{count}</p>
        ) : (
          <Skeleton count={1} className="mt-1 text-3xl font-semibold tracking-tight" />
        )}
      </div>
    </div>
  )
}

export default StatCard
