import { Alert, CustomFlowbiteTheme } from 'flowbite-react'
import React from 'react'
import { Button } from '../button'
import Drawer from '../drawer'
import FiltersDrawerItem from './item'

const customAlertTheme: CustomFlowbiteTheme['alert'] = {
  base: 'flex flex-col gap-2 p-2 text-sm',
  closeButton: {
    base: '-m-1.5 ml-auto inline-flex h-8 w-8 rounded-lg p-1.5 focus:ring-2',
    icon: 'h-5 w-5',
    color: {
      info: 'bg-cyan-100 text-cyan-500 hover:bg-cyan-200 focus:ring-cyan-400 dark:bg-cyan-200 dark:text-cyan-600 dark:hover:bg-cyan-300',
      success:
        'bg-green-100 text-green-500 hover:bg-green-200 focus:ring-green-400 dark:bg-green-200 dark:text-green-600 dark:hover:bg-green-300',
      warning:
        'bg-yellow-100 text-yellow-500 hover:bg-yellow-200 focus:ring-yellow-400 dark:bg-yellow-200 dark:text-yellow-600 dark:hover:bg-yellow-300',
      error:
        'bg-red-100 text-red-500 hover:bg-red-200 focus:ring-red-400 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300',
    },
  },
  color: {
    info: 'border-cyan-500 bg-cyan-100 text-cyan-700 dark:bg-cyan-200 dark:text-cyan-800',
    success: 'border-green-500 bg-green-100 text-green-700 dark:bg-green-200 dark:text-green-800',
    warning: 'border-yellow-500 bg-yellow-100 text-yellow-700 dark:bg-yellow-200 dark:text-yellow-800',
    error: 'border-red-500 bg-red-100 text-red-700 dark:bg-red-200 dark:text-red-800',
  },
  icon: 'mr-3 inline h-5 w-5 flex-shrink-0',
  rounded: 'rounded-md',
  wrapper: 'flex items-center',
}

export interface IFilterItem {
  key: string
  title: string
  isActive?: boolean
  type: 'checkbox' | 'radio' | 'inputSearch' | 'dateRange' | 'dropdown'
  placeholder?: string
  options?: {
    value: string
    label: string
  }[]
  value: any
  activeValue?: any
  onChange: (value: any) => void
  onClear?: () => void
  disabled?: boolean
}

export default function FilterDrawerV2({
  open,
  setOpen,
  filters,
  handleApply,
  resetHandler,
  items,
  totalResults,
  filterCount,
  alert,
}: {
  open: boolean
  setOpen: (open: boolean) => void
  filters: any
  handleApply: any
  resetHandler: any
  items: IFilterItem[]
  totalResults: number
  filterCount: number
  alert?: {
    color: 'info' | 'success' | 'warning' | 'error'
    message?: string
  }
}) {
  return (
    <Drawer
      open={open}
      setOpen={setOpen}
      title={'Filters'}
      className="z-50"
      bodyClassName="px-0 sm:px-0 w-full"
      bodyWrapperClassName="py-0"
      body={
        <>
          {items.map((item) => (
            <FiltersDrawerItem key={item.key} data={item} />
          ))}
        </>
      }
      footer={
        <div className="flex w-full flex-col gap-4">
          {alert && alert.message && (
            <Alert theme={customAlertTheme} color={alert.color}>
              <span>{alert.message}</span>
            </Alert>
          )}
          <div className="flex w-full flex-row justify-start gap-4">
            <Button
              onClick={() => {
                handleApply(filters)
                setOpen(false)
              }}
            >
              Show{` ${totalResults || ''}`} results
            </Button>
            {filterCount ? (
              <Button
                outline
                onClick={() => {
                  resetHandler()
                  setOpen(false)
                }}
              >
                Reset
              </Button>
            ) : null}
          </div>
        </div>
      }
    />
  )
}
