import { Label, Radio } from 'flowbite-react'
import React from 'react'
import { IFilterItem } from '.'

export default function FilterItemRadioCheckbox({ data }: { data: IFilterItem }) {
  return (
    <>
      {data.options &&
        data.options.map((option, i) => (
          <div key={i} className="flex items-center">
            <Radio
              id={`${data.key}-${option.value}`}
              checked={data?.value === option.value}
              name={`${data.key}-radio`}
              onChange={(e) => {
                data.onChange(option.value)
              }}
            />
            <Label htmlFor={`${data.key}-${option.value}`} className="ml-2">
              {option.label}
            </Label>
          </div>
        ))}
    </>
  )
}
