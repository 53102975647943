import { ChevronRightIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { Link } from 'react-router-dom'

export const Breadcrumb = ({ pages }: { pages: Array<{ name: string; href: string; current: boolean }> }) => {
  return (
    <nav aria-label="Breadcrumb" className="flex">
      <ol role="list" className="flex items-center space-x-1">
        {pages.map((page, index) => (
          <li key={page.name}>
            <div className="flex items-center">
              {index !== 0 && <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />}
              {!page.current ? (
                <Link
                  to={page.href}
                  aria-current={page.current ? 'page' : undefined}
                  className={`${index !== 0 ? 'ml-1' : ''}text-sm font-medium text-gray-500 hover:text-gray-700`}
                >
                  {page.name}
                </Link>
              ) : (
                <p className="ml-1 text-sm font-medium text-gray-500 hover:text-gray-700">{page.name}</p>
              )}
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}
