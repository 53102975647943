import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FetchShipmentsById } from '../firebase/model'
import { listenShipmentDetails } from '../firebase/query/shipments'
import {
  updateShipmentDetailLoader,
  updateShipmentDetails,
} from '../reduxStore/slices/shipmentDetails/shipmentDetailsSlice'
import { AppDispatch } from '../reduxStore/store'

const useShipmentDetails = (request: FetchShipmentsById) => {
  const orgId = useSelector((state: any) => state.authReducer.orgId)
  const dispatch = useDispatch<AppDispatch>()
  const loadShipmentDetails = (payload: any) => dispatch(updateShipmentDetails(payload))

  useEffect(() => {
    dispatch(updateShipmentDetailLoader())
    const shipmentDetailsListener = listenShipmentDetails({
      ...request,
      orgId,
    }, loadShipmentDetails)
    return () => shipmentDetailsListener()
  }, [dispatch, orgId, request])
}

export default useShipmentDetails
