import React from 'react'

export function CalendarEditOutline(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M18 2H16V1C16 0.447 15.552 0 15 0C14.448 0 14 0.447 14 1V2H11V1C11 0.447 10.552 0 10 0C9.448 0 9 0.447 9 1V2H6V1C6 0.447 5.552 0 5 0C4.448 0 4 0.447 4 1V2H2C0.897 2 0 2.897 0 4V18C0 19.103 0.897 20 2 20H18C19.103 20 20 19.103 20 18V4C20 2.897 19.103 2 18 2ZM2 18V8H8C8.552 8 9 7.553 9 7C9 6.447 8.552 6 8 6H2V4H4C4 4.553 4.448 5 5 5C5.552 5 6 4.553 6 4H9C9 4.553 9.448 5 10 5C10.552 5 11 4.553 11 4H14C14 4.553 14.448 5 15 5C15.552 5 16 4.553 16 4H18V6H17C16.448 6 16 6.447 16 7C16 7.553 16.448 8 17 8H18L18.001 18H2Z"
        fill="currentColor"
      />
      <path
        d="M10.515 8.005L8.859 9.682C8.84 9.698 8.817 9.704 8.799 9.722C8.78 9.741 8.773 9.766 8.756 9.786L5.288 13.297C5.104 13.484 5 13.737 5 14V16C5 16.553 5.448 17 6 17H8C8.263 17 8.516 16.896 8.703 16.712L12.214 13.244C12.234 13.227 12.259 13.221 12.278 13.202C12.296 13.184 12.302 13.161 12.318 13.142L13.999 11.481C14.958 10.522 14.958 8.96 13.999 8.002C13.04 7.041 11.479 7.041 10.515 8.005ZM7.589 15H7V14.41L9.521 11.858L10.142 12.479L7.589 15ZM12.589 10.062L11.564 11.074L10.926 10.435L11.933 9.415C12.113 9.235 12.405 9.235 12.584 9.415C12.763 9.595 12.765 9.887 12.589 10.062Z"
        fill="currentColor"
      />
    </svg>
  )
}
