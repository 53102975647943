import { XMarkIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { Filter } from '../firebase/model'
import ActiveFilterTag from './active-filter-tag'

interface IActiveFilters {
  filters: Filter[]
  columns: any[]
  handleResetFilter: (key: string) => void
  search?: string
  handleResetSearch?: () => void
}

const ActiveFilters = ({ search, filters, columns, handleResetSearch, handleResetFilter }: IActiveFilters) => {
  return (
    <>
      {search && (
        <div className="flex items-center rounded-lg bg-zinc-950/5 px-4 py-2 text-sm">
          <span>
            <strong className="font-semibold">Search:</strong> {search}&nbsp;&nbsp;
          </span>
          <span className="hover: cursor-pointer" onClick={() => handleResetSearch?.()}>
            <XMarkIcon height={15} width={15} />
          </span>
        </div>
      )}
      {filters?.map((f, i) => {
        return <ActiveFilterTag key={i} filter={f} columns={columns} handleResetFilter={handleResetFilter} />
      })}
    </>
  )
}
export default ActiveFilters
