import React from 'react'

export function FileCheck(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.3333 8.33398V4.53398C13.3333 3.41388 13.3333 2.85383 13.1153 2.426C12.9236 2.04968 12.6176 1.74372 12.2413 1.55197C11.8134 1.33398 11.2534 1.33398 10.1333 1.33398H5.86663C4.74652 1.33398 4.18647 1.33398 3.75864 1.55197C3.38232 1.74372 3.07636 2.04968 2.88461 2.426C2.66663 2.85383 2.66663 3.41388 2.66663 4.53398V11.4673C2.66663 12.5874 2.66663 13.1475 2.88461 13.5753C3.07636 13.9516 3.38232 14.2576 3.75864 14.4493C4.18647 14.6673 4.74652 14.6673 5.86663 14.6673H7.99996M9.33329 7.33398H5.33329M6.66663 10.0007H5.33329M10.6666 4.66732H5.33329M9.66663 12.6673L11 14.0007L14 11.0007"
        stroke="currentColor"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
