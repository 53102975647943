import { getDownloadURL, ref } from 'firebase/storage'
import { Button, Radio } from 'flowbite-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import EmailModalButton from 'shared/components/EmailModalButton'
import EmptyContent from 'shared/components/empty-content'
import { LoadingIcon } from 'shared/components/loading-icon'
import { Spinner } from 'shared/components/Spinner'
import { fetchDocumentById } from 'shared/firebase/query/document'
import useTaskDetails, { storage } from 'shared/hooks/useTaskDetails'
import { DocumentSuccess } from 'shared/icons/DocumentSuccess'
import { taskService } from 'shared/services'
import { handleErrorResponse } from 'shared/services/utils'
import DetailsHeader from './Components/DetailsHeader'

interface Document {
  [key: string]: string
  // Add other fields as needed based on the structure of fetchedDocument
}

type DocumentState = {
  document: Document | null
  link: string | null
}

const TaskDetailsDuplicate: React.FC = () => {
  const { taskId } = useParams<{ taskId: string }>()
  const [refreshCount, setRefreshCount] = useState(0)
  const { task, loading, error } = useTaskDetails(taskId || '', refreshCount || 0)
  const [dependentTaskId, setDependentTaskId] = useState('')
  const { task: dependentTask } = useTaskDetails(dependentTaskId || '', 0)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [documentState, setDocumentState] = useState<{
    current: DocumentState
    previous: DocumentState
  }>({
    current: { document: null, link: null },
    previous: { document: null, link: null },
  })

  const [selectedOption, setSelectedOption] = useState('')

  const fetchAndSetDocument = useCallback(
    async (docId: string, type: 'current' | 'previous') => {
      try {
        const fetchedDocument = await new Promise<Document>((resolve) =>
          fetchDocumentById({ orgId: task!.org_id, docId }, resolve)
        )

        const link =
          fetchedDocument?.original_payload_gs_link &&
          (await getDownloadURL(ref(storage, fetchedDocument.original_payload_gs_link)))

        setDocumentState((prevState) => ({
          ...prevState,
          [type]: { document: fetchedDocument, link },
        }))
      } catch (err) {
        console.error(`Error fetching ${type} document:`, err)
      }
    },
    [task]
  )

  const onConfirm = async () => {
    if (!taskId) return
    setErrorMessage('')
    setIsSubmitting(true)
    const payload = {
      id: taskId,
      version: task?.version,
      result: {
        is_received_document_approved: selectedOption === 'approve',
      },
    }

    try {
      await taskService.resolveTask(payload)
      setRefreshCount((prev) => prev + 1) // Refresh the task details
    } catch (error) {
      setErrorMessage(handleErrorResponse(error))
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if (task?.data?.received_document_id) {
      fetchAndSetDocument(task.data.received_document_id, 'current')
    } else {
      if (task?.downloadUrl) {
        setDocumentState((prevState) => ({
          ...prevState,
          current: { document: null, link: task.downloadUrl || '' },
        }))
      }
    }
  }, [task, fetchAndSetDocument])

  useEffect(() => {
    if (task?.data?.original_document_id) {
      fetchAndSetDocument(task.data.original_document_id, 'previous')
    } else {
      const dependentTaskId = task?.dependent_task_ids?.[0]
      if (dependentTaskId) {
        setDependentTaskId(dependentTaskId)
      }
    }
  }, [task, fetchAndSetDocument])

  useEffect(() => {
    if (dependentTask?.downloadUrl) {
      setDocumentState((prevState) => ({
        ...prevState,
        previous: { document: null, link: dependentTask.downloadUrl || '' },
      }))
    }
  }, [dependentTask])

  useEffect(() => {
    if (task?.status === 'completed' && task?.result) {
      if (task.result.is_received_document_approved) {
        setSelectedOption('approve')
      } else {
        setSelectedOption('ignore')
      }
    }
  }, [task])

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value)
  }

  if (loading) return <Spinner />

  if (error) {
    return <EmptyContent title="Error" description={`Error: ${error.message}`} />
  }

  if (!task) {
    return <EmptyContent title="Task not found" className="py-8" />
  }

  const { current, previous } = documentState

  return (
    <div className="flex h-screen flex-col">
      <DetailsHeader task={task} />
      <div className="height-screen-minus-navbar container m-6 mx-auto flex flex-col items-center justify-center">
        <div className="flex h-full w-full gap-6">
          {['previous', 'current'].map((type) => {
            const docType = type === 'previous' ? previous : current
            return (
              <div
                key={type}
                className="flex flex-1 flex-col overflow-y-auto rounded-lg border border-gray-200 bg-white py-4"
              >
                <div className="flex items-center justify-between gap-4 border-b px-6 pb-4">
                  <div className="flex items-center gap-2">
                    <DocumentSuccess />
                    <span className="text-lg font-semibold text-gray-900">
                      {type === 'previous' ? 'Previous Document' : 'Newly Uploaded Document'}
                    </span>
                  </div>
                  <EmailModalButton task={task} />
                </div>
                <div className="demo-image mt-4 w-full flex-1 px-4">
                  <object
                    data={docType.link || undefined}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                    className="h-full w-full object-contain"
                  >
                    <p>
                      <a href={docType.link || undefined}>See PDF</a>
                    </p>
                  </object>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="absolute bottom-0 mt-auto w-full border-t border-gray-200 bg-white p-4">
        <div className="flex flex-col items-center justify-end gap-1">
          {errorMessage && <p className="w-full text-right text-red-500">{errorMessage}</p>}
          <div className="flex w-full flex-row items-center justify-end gap-4">
            {['ignore', 'approve'].map((option) => (
              <Button
                key={option}
                color="black"
                disabled={task?.status === 'completed'}
                className={`border ${selectedOption === option ? 'border-black' : 'border-gray-200'}`}
              >
                <label className="flex cursor-pointer items-center gap-2">
                  <Radio
                    id={`${option}-document`}
                    name="duplicate"
                    value={option}
                    disabled={task?.status === 'completed'}
                    checked={selectedOption === option}
                    onChange={handleRadioChange}
                  />
                  <span>{option === 'ignore' ? 'Ignore this document' : 'Approve this duplicate'}</span>
                </label>
              </Button>
            ))}
            <button
              className="flex items-center rounded-lg border border-gray-200 bg-black px-4 py-2 text-white disabled:bg-gray-400"
              disabled={!selectedOption || isSubmitting || task?.status === 'completed'}
              onClick={onConfirm}
            >
              {isSubmitting && <LoadingIcon className="mr-2 h-5 w-5 fill-white text-gray-200" />}
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TaskDetailsDuplicate
