import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import { Divider } from './divider'

interface DrawerProps {
  open: boolean
  setOpen: (open: boolean) => void
  title: string
  body: React.ReactElement
  footer: React.ReactElement
  className?: string
  bodyClassName?: string
  bodyWrapperClassName?: string
}

export default function Drawer({
  open,
  setOpen,
  body,
  footer,
  title,
  className,
  bodyClassName,
  bodyWrapperClassName,
}: DrawerProps) {
  return (
    <Dialog open={open} onClose={setOpen} className={twMerge(['relative z-10', className])}>
      <div className="fixed inset-0" />
      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <DialogPanel
              transition
              className="pointer-events-auto w-screen max-w-[328px] transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
            >
              <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div className="px-4 py-3">
                  <div className="flex items-center justify-between">
                    <DialogTitle className="text-base font-semibold leading-6 text-gray-900">{title}</DialogTitle>
                    <div className="ml-3 flex h-7 items-center">
                      <button
                        type="button"
                        onClick={() => setOpen(false)}
                        className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      >
                        <span className="absolute -inset-2.5" />
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                      </button>
                    </div>
                  </div>
                </div>
                <Divider />
                <div className={twMerge(['flex min-h-0 flex-1 flex-col overflow-y-auto py-3', bodyWrapperClassName])}>
                  <div className={twMerge(['relative flex-1 px-4 sm:px-6', bodyClassName])}>{body}</div>
                </div>
                <div className="flex flex-shrink-0 justify-end gap-x-2 px-4 py-4">{footer}</div>
              </div>
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
