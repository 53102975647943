import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import React, { useState } from 'react'
import { Dropdown, DropdownButton, DropdownItem, DropdownMenu } from './dropdown'

import { PaginationState } from '@tanstack/react-table'
import { PaginatedQueryRequest } from '../firebase/model'

export interface DataTableFooterProps {
  pagination: PaginationState
  firstToken: any
  lastToken: any
  loading: boolean
  totalPages: number
  rowCount: number
  totalCount: number
  totalCountText?: string
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>
  setSearchQuery: React.Dispatch<React.SetStateAction<PaginatedQueryRequest>>
  setPageSize: (size: number) => void
}

export const DataTableFooter = ({
  pagination,
  firstToken,
  lastToken,
  loading,
  totalPages,
  rowCount,
  totalCountText,
  setSearchQuery,
  setPagination,
  setPageSize,
}: DataTableFooterProps) => {
  const [pageCounts] = useState([10, 20, 30])

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white py-3">
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <p className="text-sm text-gray-700">Page {pagination.pageIndex}</p>
        <div className="flex items-center gap-3">
          <p className="text-sm text-gray-700">{totalCountText}</p>
          <div>
            <nav aria-label="Pagination" className="isolate inline-flex -space-x-px rounded-md shadow-sm">
              <button
                onClick={(e) => {
                  setSearchQuery((prevRequest: PaginatedQueryRequest) => ({
                    ...prevRequest,
                    direction: 'prev',
                    pageToken: firstToken,
                  }))
                  setPagination((prev: PaginationState) => ({
                    ...prev,
                    pageIndex: prev.pageIndex - 1,
                  }))
                }}
                disabled={!loading ? pagination.pageIndex === 1 : true}
                className="relative inline-flex cursor-pointer items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:cursor-not-allowed disabled:bg-gray-200"
              >
                <span className="sr-only">Previous</span>
                <ChevronLeftIcon aria-hidden="true" className="h-5 w-5" />
              </button>
              <button
                onClick={(e) => {
                  setSearchQuery((prevRequest: PaginatedQueryRequest) => ({
                    ...prevRequest,
                    pageSize: pagination.pageSize,
                    direction: 'next',
                    pageToken: lastToken,
                  }))
                  setPagination((prev) => ({
                    ...prev,
                    pageIndex: prev.pageIndex + 1,
                  }))
                }}
                disabled={!loading ? pagination.pageIndex === totalPages : true}
                className="relative inline-flex cursor-pointer items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 disabled:cursor-not-allowed disabled:bg-gray-200"
              >
                <span className="sr-only">Next</span>
                <ChevronRightIcon aria-hidden="true" className="h-5 w-5" />
              </button>
            </nav>
          </div>
          <Dropdown>
            <DropdownButton
              className="BORDER-2 cursor-pointer rounded-r-md border-[#e5e7eb] shadow-sm ring-0 ring-inset ring-gray-300 focus:z-20 focus:outline-offset-0"
              outline
            >
              Show {rowCount}
              <ChevronDownIcon />
            </DropdownButton>
            <DropdownMenu>
              {pageCounts?.map((pc) => {
                return (
                  <DropdownItem
                    className={`cursor-pointer ${
                      pc === pagination.pageSize ? 'bg-zinc-950 !text-white' : ''
                    } hover:!bg-zinc-950 hover:!text-white`}
                    onClick={(e: any) => {
                      setPageSize(Number(pc))
                      setPagination((pagination) => ({
                        ...pagination,
                        pageSize: pc,
                        pageIndex: 1,
                      }))
                      setSearchQuery((prevReq: PaginatedQueryRequest) => {
                        delete prevReq.pageToken
                        return {
                          ...prevReq,
                          pageSize: pc,
                          direction: '',
                        }
                      })
                    }}
                    key={pc}
                  >
                    Show {pc}
                  </DropdownItem>
                )
              })}
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    </div>
  )
}
