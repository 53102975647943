import React from 'react'
import { GhostIcon } from 'shared/icons/Ghost'
import { twMerge } from 'tailwind-merge'

interface IProps {
  title?: string
  description?: string
  action?: React.ReactNode
  className?: string
}

const EmptyContent = ({ title, description, action, className }: IProps) => {
  return (
    <div
      className={twMerge([
        'mx-auto flex h-full w-full max-w-96 flex-col items-center justify-center space-y-2 py-8',
        className,
      ])}
    >
      <GhostIcon className="text-black" />
      <h4 className="text-center text-2xl font-semibold text-gray-900">{title || 'No Data Available'}</h4>
      {description && <p className="whitespace-pre-line text-center text-gray-500">{description}</p>}
      <div className="flex gap-2 pt-2">{action}</div>
    </div>
  )
}

export default EmptyContent
