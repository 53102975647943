import React from 'react'

export function ClipboardListOutline(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.1296 1H13.7909C13.6136 0.696959 13.3581 0.445042 13.0503 0.269523C12.7425 0.0940032 12.393 0.00105394 12.037 0H7.96296C7.60698 0.00105394 7.25754 0.0940032 6.9497 0.269523C6.64186 0.445042 6.38644 0.696959 6.20907 1H2.87037C2.33012 1 1.81199 1.21071 1.42997 1.58579C1.04795 1.96086 0.833334 2.46957 0.833334 3V18C0.833334 18.5304 1.04795 19.0391 1.42997 19.4142C1.81199 19.7893 2.33012 20 2.87037 20H17.1296C17.6699 20 18.188 19.7893 18.57 19.4142C18.9521 19.0391 19.1667 18.5304 19.1667 18V3C19.1667 2.46957 18.9521 1.96086 18.57 1.58579C18.188 1.21071 17.6699 1 17.1296 1ZM12.037 2V4H7.96296V2H12.037ZM17.1296 18H2.87037V3H5.92593V4C5.6558 4 5.39673 4.10536 5.20573 4.29289C5.01472 4.48043 4.90741 4.73478 4.90741 5C4.90741 5.26522 5.01472 5.51957 5.20573 5.70711C5.39673 5.89464 5.6558 6 5.92593 6H14.0741C14.3442 6 14.6033 5.89464 14.7943 5.70711C14.9853 5.51957 15.0926 5.26522 15.0926 5C15.0926 4.73478 14.9853 4.48043 14.7943 4.29289C14.6033 4.10536 14.3442 4 14.0741 4V3H17.1296V18Z"
        fill="currentColor"
      />
      <path
        d="M14.0741 9H8.98148C8.71135 9 8.45229 9.10536 8.26128 9.29289C8.07027 9.48043 7.96296 9.73478 7.96296 10C7.96296 10.2652 8.07027 10.5196 8.26128 10.7071C8.45229 10.8946 8.71135 11 8.98148 11H14.0741C14.3442 11 14.6033 10.8946 14.7943 10.7071C14.9853 10.5196 15.0926 10.2652 15.0926 10C15.0926 9.73478 14.9853 9.48043 14.7943 9.29289C14.6033 9.10536 14.3442 9 14.0741 9Z"
        fill="currentColor"
      />
      <path
        d="M14.0741 13H8.98148C8.71135 13 8.45229 13.1054 8.26128 13.2929C8.07027 13.4804 7.96296 13.7348 7.96296 14C7.96296 14.2652 8.07027 14.5196 8.26128 14.7071C8.45229 14.8946 8.71135 15 8.98148 15H14.0741C14.3442 15 14.6033 14.8946 14.7943 14.7071C14.9853 14.5196 15.0926 14.2652 15.0926 14C15.0926 13.7348 14.9853 13.4804 14.7943 13.2929C14.6033 13.1054 14.3442 13 14.0741 13Z"
        fill="currentColor"
      />
      <path
        d="M5.92593 11C6.48844 11 6.94445 10.5523 6.94445 10C6.94445 9.44771 6.48844 9 5.92593 9C5.36341 9 4.90741 9.44771 4.90741 10C4.90741 10.5523 5.36341 11 5.92593 11Z"
        fill="currentColor"
      />
      <path
        d="M5.92593 15C6.48844 15 6.94445 14.5523 6.94445 14C6.94445 13.4477 6.48844 13 5.92593 13C5.36341 13 4.90741 13.4477 4.90741 14C4.90741 14.5523 5.36341 15 5.92593 15Z"
        fill="currentColor"
      />
    </svg>
  )
}
