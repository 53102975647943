import { DocumentType } from 'shared/components/models/documentTypeMap'

export const getDocumentTypeOptions = (): {
  value: string
  label: string
}[] => {
  return Object.keys(DocumentType).map((key) => ({
    value: key,
    label: DocumentType[key as keyof typeof DocumentType],
  }))
}

export const getDocumentTypeDisplayName = (type: string): string => {
  return DocumentType[type as keyof typeof DocumentType] || type
}
