import { initializeApp } from 'firebase/app'
import { collection, doc, getDoc, getDocs, getFirestore, query, where } from 'firebase/firestore'
import { getDownloadURL, getStorage, ref } from 'firebase/storage'
import { useEffect, useState } from 'react'
import { firebaseConfig } from '../firebase/config'

// Initialize Firebase app, Firestore, and Storage
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
export const storage = getStorage(app)

export interface Task {
  id: string
  data: {
    file_name?: string
    received_payload_gs_link?: string
    email_attachment_filename?: string
    primary_data_source_org?: string
    [key: string]: any
  }
  downloadUrl?: string
  addressesData?: any[] // Store all matching addresses
  [key: string]: any
  schedule: {
    [key: string]: any
  }
}

export interface DocumentTypeMap {
  [key: string]: any
}

export interface DocumentData {
  id: string
  type: string
}

const useTaskDetails = (taskId: string, refreshCount: number) => {
  const [task, setTask] = useState<Task | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const fetchTaskDetails = async () => {
      setLoading(true)
      try {
        const docRef = doc(db, 'tasks', taskId)
        const docSnap = await getDoc(docRef)

        if (docSnap.exists()) {
          const data = docSnap.data()
          let addressesData: any[] = [] // Array to store multiple addresses

          if (data.data.primary_data_source_org) {
            // Query the addresses collection
            const addressQuery = query(
              collection(db, 'addresses'),
              where('primary_data_source_reference.org_code', '==', data.data.primary_data_source_org)
            )
            const addressQuerySnapshot = await getDocs(addressQuery)
            addressesData = addressQuerySnapshot.docs.map((doc) => doc.data()) // Store all matched addresses
          }

          if (data.data.received_payload_gs_link) {
            // Get the download URL from Firebase Storage
            const fileRef = ref(storage, data.data.received_payload_gs_link)
            const downloadUrl = await getDownloadURL(fileRef)
            setTask({ schedule: {}, data: {}, id: docSnap.id, ...data, downloadUrl, addressesData })
          } else {
            setTask({ schedule: {}, data: {}, id: docSnap.id, ...data, addressesData })
          }
        } else {
          console.log('No such document!')
          setTask(null)
        }
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Unknown error'))
        console.error('Error fetching task details:', err)
      } finally {
        setLoading(false)
      }
    }

    if (taskId) {
      fetchTaskDetails()
    }
  }, [taskId, refreshCount])

  return { task, loading, error }
}

export default useTaskDetails
