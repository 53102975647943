import React from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'shared/components/button'
import { Dialog, DialogActions, DialogBody, DialogTitle } from 'shared/components/dialog'

export const EmailModal = ({ isOpen, setIsOpen }: any) => {
  const document = useSelector((state: any) => state.documentDetailReducer)

  return (
    <Dialog open={isOpen} onClose={setIsOpen} size="xl">
      <DialogTitle className="border-b py-3">{document.data?.metadata?.received_email_subject}</DialogTitle>
      <DialogBody className="border-b text-sm/6 text-zinc-900 dark:text-white">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Officia id cupiditate et iusto distinctio a explicabo
        repellendus quisquam qui, cum porro, aperiam deserunt placeat perferendis accusamus nemo, quibusdam sed! Rem!
      </DialogBody>
      <DialogActions className="!mt-5">
        <Button plain onClick={() => setIsOpen(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
