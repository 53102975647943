import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react'
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Checkbox, Label, TextInput } from 'flowbite-react'
import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { HiSearch } from 'react-icons/hi'
import { twMerge } from 'tailwind-merge'
import { IFilterItem } from '.'

interface IOption {
  value: string
  label: string
}

export default function FilterItemDropdown({
  data: { key, disabled, options, value, onChange, placeholder },
  hasCombobox = true,
}: {
  hasCombobox?: boolean
  data: IFilterItem
}) {
  const [query, setQuery] = useState('')
  const [indeterminate, setIndeterminate] = useState(false)
  const selectAllRef = useRef<HTMLInputElement>(null)

  const filteredOptions =
    query === ''
      ? options
      : options?.filter((item) => {
          return item.label.toLowerCase().includes(query.toLowerCase())
        })

  useEffect(() => {
    if (value.length === 0) {
      setIndeterminate(false)
    } else if (value.length === filteredOptions?.length) {
      setIndeterminate(false)
    } else {
      setIndeterminate(true)
    }
  }, [value, filteredOptions])

  useLayoutEffect(() => {
    if (selectAllRef.current) {
      selectAllRef.current.indeterminate = indeterminate
    }
  }, [indeterminate])
  if (hasCombobox)
    return (
      <div className="w-full">
        <Combobox
          value={value || []}
          multiple
          disabled={disabled}
          onChange={(_value) => {
            onChange(_value)
          }}
          onClose={() => setQuery('')}
        >
          <div className={twMerge(['relative', disabled ? 'opacity-50' : ''])}>
            <ComboboxInput as={Fragment}>
              <TextInput
                icon={HiSearch}
                value={query}
                disabled={disabled}
                onChange={(e) => setQuery(e.target.value)}
                placeholder={placeholder || 'Search'}
                type="search"
              />
            </ComboboxInput>
            <ComboboxButton className="group absolute inset-y-0 right-0 px-2.5">
              {query ? (
                <XMarkIcon className="size-4 fill-white group-data-[hover]:fill-white" onClick={() => setQuery('')} />
              ) : (
                <ChevronDownIcon className="size-4 fill-white group-data-[hover]:fill-white" />
              )}
            </ComboboxButton>
          </div>

          <ComboboxOptions
            anchor="bottom"
            transition
            className={twMerge(
              'w-[var(--input-width)] rounded-xl border border-gray-200 bg-white p-1 [--anchor-gap:var(--spacing-1)] empty:invisible',
              'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0'
            )}
          >
            {(filteredOptions?.length || 0) > 0 && (
              <div
                className="group flex cursor-default select-none items-center gap-2 rounded-lg px-3 py-1.5 data-[focus]:bg-white"
                onClick={() => {
                  if (value.length === filteredOptions?.length) {
                    onChange([])
                  } else {
                    onChange(filteredOptions?.map((o) => o.value) || [])
                  }
                }}
              >
                <Checkbox disabled={disabled} ref={selectAllRef} checked={value.length === filteredOptions?.length} />
                <div className={twMerge('text-sm font-medium text-gray-900', disabled ? 'opacity-50' : '')}>
                  Select All
                </div>
              </div>
            )}
            {filteredOptions?.map((item, index) => (
              <ComboboxOption
                key={`option-${item.value}-${index}`}
                value={item.value}
                className="group flex cursor-default select-none items-center gap-2 rounded-lg px-3 py-1.5 data-[focus]:bg-white"
              >
                <Checkbox disabled={disabled} checked={value?.includes(item.value)} />
                <div className={twMerge('text-sm font-medium text-gray-900', disabled ? 'opacity-50' : '')}>
                  {item.label}
                </div>
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </Combobox>
        {value.length > 0 && (
          <div className={twMerge('mt-2 flex w-full flex-col gap-2 p-1')}>
            {value.map((v: string) => {
              const item = options?.find((o: IOption) => o.value === v)
              if (!item) return null
              return (
                <div key={`selected-${item.value}`} className={twMerge('flex items-center gap-2')}>
                  <Checkbox
                    id={`selected-${item.value}`}
                    checked
                    disabled={disabled}
                    onChange={() => {
                      onChange(value.filter((s: string) => s !== item.value))
                    }}
                  />
                  <Label
                    disabled={disabled}
                    htmlFor={`selected-${item.value}`}
                    className="text-sm font-medium text-gray-900"
                  >
                    {item.label}
                  </Label>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  return (
    <div className="w-full">
      <div className="relative">
        <TextInput
          icon={HiSearch}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder={placeholder || 'Search'}
          type="search"
        />
        <button className="group absolute inset-y-0 right-0 px-2.5">
          {query && (
            <XMarkIcon className="size-4 fill-white group-data-[hover]:fill-white" onClick={() => setQuery('')} />
          )}
        </button>
      </div>
      {query && (
        <div className="flex w-full flex-col gap-2 p-1">
          {(filteredOptions?.length || 0) > 0 && (
            <div className="group flex cursor-default select-none items-center gap-2 rounded-lg px-0 py-1 data-[focus]:bg-white">
              <Checkbox
                ref={selectAllRef}
                id={`select-all-${key}`}
                checked={value.length === filteredOptions?.length}
                onChange={() => {
                  if (value.length === filteredOptions?.length) {
                    onChange([])
                  } else {
                    onChange(filteredOptions?.map((o) => o.value) || [])
                  }
                }}
              />
              <label htmlFor={`select-all-${key}`} className="text-sm text-gray-900">
                Select All
              </label>
            </div>
          )}
          {filteredOptions?.map((item, index) => (
            <div
              key={`option-${item.value}-${index}`}
              className="group flex cursor-default select-none items-center gap-2 rounded-lg px-0 py-1 data-[focus]:bg-white"
            >
              <Checkbox
                id={`option-${item.value}-${index}`}
                checked={value?.includes(item.value)}
                onChange={() => {
                  if (value.includes(item.value)) {
                    onChange(value.filter((s: string) => s !== item.value))
                  } else {
                    onChange([...value, item.value])
                  }
                }}
              />
              <label htmlFor={`option-${item.value}-${index}`} className="text-sm text-gray-900">
                {item.label}
              </label>
            </div>
          ))}
        </div>
      )}
      {!query && value.length > 0 && (
        <div className={twMerge('mt-2 flex w-full flex-col gap-2 p-1')}>
          {value.map((v: string) => {
            const item = options?.find((o: IOption) => o.value === v)
            if (!item) return null
            return (
              <div key={`selected-${item.value}`} className={twMerge('flex items-center gap-2')}>
                <Checkbox
                  id={`selected-${item.value}-${key}`}
                  checked
                  onChange={() => {
                    onChange(value.filter((s: string) => s !== item.value))
                  }}
                />
                <label htmlFor={`selected-${item.value}-${key}`} className="text-sm text-gray-900">
                  {item.label}
                </label>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}
