import React from 'react'

export function Filter(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0.547208 1.36158L0.547214 1.36158L0.545492 1.35934C0.519499 1.32545 0.504507 1.28631 0.500874 1.24646C0.497243 1.20664 0.504945 1.16615 0.523902 1.12927C0.542915 1.09227 0.57294 1.0596 0.611984 1.03617C0.651118 1.01268 0.697148 0.999815 0.744791 1V1.00001H0.74675L11.2532 1.00001L11.2552 1C11.3028 0.999815 11.3489 1.01268 11.388 1.03617C11.4271 1.0596 11.4571 1.09227 11.4761 1.12926C11.4951 1.16616 11.5028 1.20664 11.4991 1.24646L11.9971 1.29187L11.4991 1.24646C11.4955 1.2863 11.4805 1.32545 11.4545 1.35933L11.4528 1.36158L7.09727 7.10704L6.99572 7.24099V7.40909V11.5165L5.00428 10.064V7.40909V7.24099L4.90273 7.10704L0.547208 1.36158Z"
        fill="currentColor"
        stroke={props.stroke || '#111928'}
      />
    </svg>
  )
}
