import { TextInput } from 'flowbite-react'
import React from 'react'
import { HiSearch } from 'react-icons/hi'
import { IFilterItem } from '.'

export default function FilterItemSearchInput({ data }: { data: IFilterItem }) {
  return (
    <TextInput
      icon={HiSearch}
      id="search"
      name={data.key}
      value={data.value}
      onChange={(e) => data.onChange(e.target.value)}
      placeholder={data.placeholder || 'Search'}
      type="search"
    />
  )
}
