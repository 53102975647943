import { Accordion, Dropdown, Modal } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import EmptyContent from 'shared/components/empty-content'
import { LoadingIcon } from 'shared/components/loading-icon'
import { Spinner } from 'shared/components/Spinner'
import { fetchDocumentById } from 'shared/firebase/query/document'
import { listenShipmentDetails } from 'shared/firebase/query/shipments'
import { listenTaskDetails } from 'shared/firebase/query/tasks'
import { getDocumentTypeDisplayName, taskService } from 'shared/services'
import { handleErrorResponse } from 'shared/services/utils'
import { twMerge } from 'tailwind-merge'
import { routesKeys } from '../Shipments/Details/model'
import DetailsHeader from './Components/DetailsHeader'
type OptionType = { value: string; label: string; custom?: boolean }

function formatString(value: string) {
  return value
    .split('_') // Split the string into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(' ') // Join the words with a space
}

const TaskDiscrepancy: React.FC = () => {
  const { taskId } = useParams<{ taskId: string }>()
  const [task, setTask] = useState<any>(null)
  const [loadingTask, setLoadingTask] = useState(true)
  const [shipment, setShipment] = useState<any>(null)
  const [loadingShipment, setLoadingShipment] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [currentField, setCurrentField] = useState<string | null>(null) // Track the field name for modal
  const [customValue, setCustomValue] = useState<string>('') // Store the custom input value
  const [documentDetails, setDocumentDetails] = useState<Record<string, any>>({})
  const [selectedValues, setSelectedValues] = useState<Record<string, string>>({})
  const [customValues, setCustomValues] = useState<Record<string, string>>({}) // Track custom values per field
  const [isLoading, setIsLoading] = useState(false) // Track loading state for Confirm button
  const [errorMessage, setErrorMessage] = useState('') // Track API errors
  const [issueCount, setIssueCount] = useState(0)
  const [solvedCount, setSolvedCount] = useState(0)
  const orgId = useSelector((state: any) => state.authReducer.orgId)
  const [refreshCount, setRefreshCount] = useState(0)
  // Listen for task updates
  useEffect(() => {
    if (taskId) {
      const unsubscribe = listenTaskDetails({ taskId, orgId: orgId, refetch: refreshCount }, (updatedTask) => {
        setTask(updatedTask)
        setLoadingTask(false)
      })

      return () => unsubscribe() // Cleanup on unmount
    }
    return () => {} // Return an empty function if no subscription is created
  }, [taskId, refreshCount, orgId])

  // Fetch Shipment Details
  useEffect(() => {
    if (task?.data?.shipment_ids?.[0]) {
      const shipmentId = task.data.shipment_ids[0]
      setLoadingShipment(true)
      listenShipmentDetails({ shipmentId, orgId: task.org_id, refetch: 0 }, (shipmentDetails) => {
        setShipment(shipmentDetails)
        setLoadingShipment(false)
      })
    }
  }, [task])

  const discrepancyRecords = React.useMemo(() => {
    return (
      shipment?.validation_result?.records?.filter(
        (record: any) => record.status === 'discrepancy' || record.status === 'overridden'
      ) || []
    )
  }, [shipment])

  React.useEffect(() => {
    const discrepancyCount = discrepancyRecords.filter((record: any) => record.status === 'discrepancy').length

    const overriddenCount = discrepancyRecords.filter((record: any) => record.status === 'overridden').length

    setIssueCount(discrepancyCount)
    setSolvedCount(overriddenCount)
  }, [discrepancyRecords])

  const fetchDocument = (docId: string, orgId: string) => {
    if (!documentDetails[docId]) {
      fetchDocumentById({ orgId, docId }, (document: any) =>
        setDocumentDetails((prev) => ({ ...prev, [docId]: document }))
      )
    }
  }

  const handleValueChange = (fieldName: string, value: string) => {
    setSelectedValues((prev) => ({ ...prev, [fieldName]: value }))
  }

  const handleModalOpen = (fieldName: string) => {
    setCurrentField(fieldName) // Set the active field for the modal
    setCustomValue(customValues[fieldName] || '') // Pre-fill with existing custom value, if any
    setShowModal(true)
  }

  const handleModalSubmit = () => {
    if (currentField) {
      setCustomValues((prev) => ({ ...prev, [currentField]: customValue })) // Save the custom value
      handleValueChange(currentField, customValue) // Set the custom value as selected
      setShowModal(false) // Close the modal
    }
  }

  // Determine if Confirm button should be disabled
  const isConfirmDisabled = Object.keys(selectedValues).length === 0 || isLoading

  const handleConfirmClick = async () => {
    if (!taskId || isLoading) return // Prevent empty submission and multiple clicks

    setIsLoading(true) // Start loading

    type TransformedValues = Record<string, any>

    const transformedValues = Object.entries(selectedValues).reduce<TransformedValues>((acc, [key, value]) => {
      const routeKey = routesKeys.find((route) => route.key === key)
      if (!routeKey) return { ...acc, [key]: value }

      const resolvedValue = value

      const accesorValue = routeKey.accesorKey ? { [routeKey.accesorKey]: resolvedValue } : resolvedValue

      acc[key] = routeKey.appendKey
        ? { ...(typeof accesorValue === 'object' ? accesorValue : {}), [routeKey.appendKey]: 'unit' }
        : accesorValue

      return acc
    }, {})

    const payload = {
      id: taskId,
      version: task?.version,
      result: {
        shipment_reference: {
          id: shipment?.id,
          version: shipment?.version,
        },
        shipment_data: transformedValues,
      },
    }

    try {
      setErrorMessage('')
      await taskService.resolveTask(payload)
      setRefreshCount((prev) => prev + 1) // Refresh the task details
    } catch (err) {
      setErrorMessage(handleErrorResponse(err))
    } finally {
      setIsLoading(false) // Stop loading
    }
  }

  const getResolvedValue = (fieldName: string, data: any): any | undefined => {
    if (!data || !fieldName) return undefined

    const routeKey = routesKeys.find((route) => route.key === fieldName)
    if (!routeKey) return undefined

    let fieldValue = data[fieldName]

    // Handle fallBackValidationKey if primary field is undefined
    if (!fieldValue && routeKey.fallBackValidationKey) {
      fieldValue = data[routeKey.fallBackValidationKey]
    }

    // Handle accesorKey if fieldValue is an object
    if (typeof fieldValue === 'object' && fieldValue !== null && routeKey.accesorKey) {
      fieldValue = fieldValue[routeKey.accesorKey]
    }

    // Handle timestamps
    if (routeKey.isTimestamp && typeof fieldValue === 'number') {
      fieldValue = new Date(fieldValue * 1000).toISOString() // Convert seconds to ISO string
    }

    // Ensure the value is a string
    return fieldValue ? fieldValue : undefined
  }

  if (loadingTask || loadingShipment) {
    return <Spinner />
  }

  if (!task) return <EmptyContent title="Task not found" className="py-8" />

  return (
    <div className="flex min-h-screen flex-col">
      <DetailsHeader task={task} />
      <div className="height-screen-minus-navbar container m-6 mx-auto flex flex-col items-center justify-center">
        <div className="h-full w-full overflow-y-auto">
          <Accordion>
            <Accordion.Panel>
              <Accordion.Title className="relative bg-transparent px-4 py-3 text-sm dark:bg-transparent">
                <IssueBadge issueCount={issueCount} solvedCount={solvedCount} />
                <div className="flex items-center gap-2">
                  {issueCount === 0 ? <Icon type="success" /> : <Icon type="exclamation" />}
                  <span className="text-md font-bold text-black">Shipment Data</span>
                </div>
              </Accordion.Title>
              <Accordion.Content className="p-4 px-4 dark:bg-gray-800">
                <div className="grid grid-cols-2 gap-4">
                  {discrepancyRecords.map((record: any, index: number) => {
                    const documentIds = record.field_values
                      .flatMap((fieldValue: any) => fieldValue.shipping_document_ids)
                      .filter(Boolean)

                    documentIds.forEach((docId: any) => fetchDocument(docId, task.org_id))

                    const documents = documentIds.map((docId: any) => documentDetails[docId]).filter(Boolean)

                    // Resolve the current value dynamically based on shipment.data and routesKeys
                    const resolvedValue = getResolvedValue(record.field_name, shipment?.data)
                    const currentValue = selectedValues[record.field_name] || resolvedValue || ''

                    return (
                      <DiscrepancySection
                        key={index}
                        label={record.field_name}
                        options={record.field_values.map((value: any) => ({
                          value: value.value,
                          label: `${value.value}`,
                          custom: false,
                        }))}
                        customValue={customValues[record.field_name] || null}
                        currentValue={currentValue}
                        onValueChange={(value: string) => handleValueChange(record.field_name, value)}
                        onModalOpen={() => handleModalOpen(record.field_name)}
                        documents={documents}
                        fieldValues={record.field_values}
                        record={record}
                        selectedValues={selectedValues}
                        task={task}
                      />
                    )
                  })}
                </div>
              </Accordion.Content>
            </Accordion.Panel>
          </Accordion>
        </div>
      </div>
      <div className="absolute bottom-0 mt-auto w-full border-t border-gray-200 bg-white p-4">
        <div className="flex flex-col items-center justify-end gap-1">
          {errorMessage && <p className="w-full text-right text-red-500">{errorMessage}</p>}
          <div className="flex w-full flex-row items-center justify-end gap-4">
            <button
              disabled={isConfirmDisabled}
              onClick={handleConfirmClick}
              className={twMerge([
                'flex flex-row rounded-lg border px-4 py-2 text-white',
                isConfirmDisabled ? 'cursor-not-allowed bg-gray-300' : 'bg-black hover:bg-gray-700',
              ])}
            >
              {isLoading && <LoadingIcon className="mr-2 h-5 w-5 fill-white text-gray-200" />}
              Confirm
            </button>
          </div>
        </div>
      </div>
      <CorrectValueModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        fieldName={currentField}
        customValue={customValue}
        setCustomValue={setCustomValue}
        onSubmit={handleModalSubmit}
      />
    </div>
  )
}

const IssueBadge = ({ issueCount, solvedCount }: { issueCount: number; solvedCount: number }) => {
  const badgeStyle =
    issueCount === 0 && solvedCount === 0
      ? 'bg-green-100 text-green-800'
      : issueCount > 0
        ? 'bg-red-100 text-red-800'
        : 'bg-green-100 text-green-800'

  const badgeText =
    issueCount === 0 && solvedCount === 0
      ? 'No Issues'
      : issueCount > 0
        ? `${issueCount} ${issueCount === 1 ? 'Issue' : 'Issues'}`
        : `${solvedCount} ${solvedCount === 1 ? 'Issue Fixed' : 'Issues Fixed'}`

  return (
    <span className={`absolute right-12 me-2 rounded px-2.5 py-0.5 text-xs font-medium ${badgeStyle}`}>
      {badgeText}
    </span>
  )
}

const Icon = ({ type, green }: { type: string; green?: boolean }) => {
  switch (type) {
    case 'exclamation':
      return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_156_34726)">
            <path
              d="M8 0.5C6.41775 0.5 4.87103 0.969192 3.55544 1.84824C2.23985 2.72729 1.21447 3.97672 0.608967 5.43853C0.00346627 6.90034 -0.15496 8.50887 0.153721 10.0607C0.462403 11.6126 1.22433 13.038 2.34315 14.1569C3.46197 15.2757 4.88743 16.0376 6.43928 16.3463C7.99113 16.655 9.59966 16.4965 11.0615 15.891C12.5233 15.2855 13.7727 14.2602 14.6518 12.9446C15.5308 11.629 16 10.0822 16 8.5C15.9977 6.37898 15.1541 4.3455 13.6543 2.84572C12.1545 1.34593 10.121 0.502329 8 0.5ZM8 12.5C7.84178 12.5 7.68711 12.4531 7.55555 12.3652C7.42399 12.2773 7.32145 12.1523 7.2609 12.0061C7.20035 11.86 7.18451 11.6991 7.21537 11.5439C7.24624 11.3887 7.32243 11.2462 7.43432 11.1343C7.5462 11.0224 7.68875 10.9462 7.84393 10.9154C7.99911 10.8845 8.15997 10.9003 8.30615 10.9609C8.45233 11.0214 8.57727 11.124 8.66518 11.2555C8.75308 11.3871 8.8 11.5418 8.8 11.7C8.8 11.9122 8.71572 12.1157 8.56569 12.2657C8.41566 12.4157 8.21218 12.5 8 12.5ZM8.8 9.3C8.8 9.51217 8.71572 9.71565 8.56569 9.86568C8.41566 10.0157 8.21218 10.1 8 10.1C7.78783 10.1 7.58435 10.0157 7.43432 9.86568C7.28429 9.71565 7.2 9.51217 7.2 9.3V5.3C7.2 5.08783 7.28429 4.88434 7.43432 4.73431C7.58435 4.58428 7.78783 4.5 8 4.5C8.21218 4.5 8.41566 4.58428 8.56569 4.73431C8.71572 4.88434 8.8 5.08783 8.8 5.3V9.3Z"
              fill="#E02424"
            />
          </g>
          <defs>
            <clipPath id="clip0_156_34726">
              <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
            </clipPath>
          </defs>
        </svg>
      )
    case 'success':
      return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346627 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87898 15.1541 3.8455 13.6543 2.34572C12.1545 0.845932 10.121 0.00232928 8 0ZM10.9656 6.9656L7.7656 10.1656C7.61558 10.3156 7.41213 10.3998 7.2 10.3998C6.98787 10.3998 6.78442 10.3156 6.6344 10.1656L5.0344 8.5656C4.88868 8.41472 4.80804 8.21263 4.80986 8.00288C4.81169 7.79312 4.89582 7.59247 5.04415 7.44414C5.19247 7.29582 5.39312 7.21168 5.60288 7.20986C5.81264 7.20804 6.01472 7.28867 6.1656 7.4344L7.2 8.4688L9.8344 5.8344C9.98528 5.68867 10.1874 5.60804 10.3971 5.60986C10.6069 5.61168 10.8075 5.69582 10.9559 5.84414C11.1042 5.99247 11.1883 6.19312 11.1901 6.40288C11.192 6.61263 11.1113 6.81472 10.9656 6.9656Z"
            fill="#0E9F6E"
          />
        </svg>
      )
    case 'warning':
      return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.3934 6.15855L9.4857 1.95855C9.43046 1.87871 9.35666 1.81347 9.27065 1.76843C9.18463 1.72338 9.08898 1.69989 8.99188 1.69995H1.09982C0.990351 1.69999 0.88298 1.72997 0.789331 1.78665C0.695682 1.84333 0.619324 1.92455 0.568523 2.02151C0.517723 2.11847 0.494417 2.22748 0.501129 2.33674C0.507841 2.446 0.544315 2.55134 0.606601 2.64135L3.27849 6.49995L0.606601 10.3586C0.544315 10.4486 0.507841 10.5539 0.501129 10.6632C0.494417 10.7724 0.517723 10.8814 0.568523 10.9784C0.619324 11.0754 0.695682 11.1566 0.789331 11.2133C0.88298 11.2699 0.990351 11.2999 1.09982 11.3H8.99188C9.08898 11.3 9.18463 11.2765 9.27065 11.2315C9.35666 11.1864 9.43046 11.1212 9.4857 11.0414L12.3934 6.84135C12.4628 6.74103 12.5 6.62194 12.5 6.49995C12.5 6.37796 12.4628 6.25887 12.3934 6.15855Z"
            fill={green ? '#057A55' : '#9B1C1C'}
          />
        </svg>
      )
    case 'document':
      return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.6663 7.29224V3.96724C11.6663 2.98714 11.6663 2.4971 11.4756 2.12275C11.3078 1.79347 11.0401 1.52575 10.7108 1.35797C10.3365 1.16724 9.84643 1.16724 8.86634 1.16724H5.13301C4.15292 1.16724 3.66287 1.16724 3.28852 1.35797C2.95924 1.52575 2.69152 1.79347 2.52375 2.12275C2.33301 2.4971 2.33301 2.98714 2.33301 3.96724V10.0339C2.33301 11.014 2.33301 11.504 2.52375 11.8784C2.69152 12.2077 2.95924 12.4754 3.28852 12.6432C3.66287 12.8339 4.15292 12.8339 5.13301 12.8339H6.99967M8.16634 6.41724H4.66634M5.83301 8.75057H4.66634M9.33301 4.0839H4.66634M8.45801 11.0839L9.62467 12.2506L12.2497 9.62557"
            stroke="#4B5563"
            strokeWidth="1.16667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )
    default:
      return null
  }
}

const DiscrepancySection = ({
  label,
  currentValue,
  onValueChange,
  onModalOpen,
  documents,
  fieldValues,
  customValue,
  record,
  selectedValues,
  task,
}: {
  label: string
  options: { value: string; label: string; custom?: boolean }[]
  currentValue: string
  onValueChange: (value: string) => void
  onModalOpen: () => void
  documents: any[]
  fieldValues: any[]
  customValue: string | null
  record: any
  selectedValues: any
  task: any
}) => {
  const documentMap = Object.fromEntries(documents.map((doc) => [doc.id, doc.type || 'Unknown']))
  const options: OptionType[] = fieldValues.map((fieldValue) => {
    const docType = fieldValue.shipping_document_ids
      .map((docId: string) => documentMap[docId])
      .filter(Boolean)
      .join(', ')
    return {
      value: fieldValue.value,
      label: `${docType || 'Unknown Source'}: ${fieldValue.value}`,
    }
  })

  const isOverridden = fieldValues.some((fieldValue: any) => record.status === 'overridden')

  // Add the custom value as a third option if it exists
  if (customValue || (isOverridden && currentValue)) {
    const existsInOptions = options.some((option) => option.value === currentValue)

    if (!existsInOptions) {
      options.push({
        value: currentValue,
        label: `${currentValue}`,
        custom: true,
      })
    }
  }

  return (
    <div className="relative flex-1 justify-center rounded-lg border p-4 text-center">
      <DropdownComponent documents={documents} />
      <span
        className={`mb-6 me-2 mt-4 inline-flex items-center gap-1 rounded px-2.5 py-0.5 text-xs font-medium ${
          isOverridden ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
        }`}
      >
        <Icon type="warning" green={isOverridden} />
        {formatString(label)}
      </span>
      <p className="text-[16px] font-bold">Select corrected value</p>
      <ValueOptions
        options={options}
        currentValue={currentValue}
        onValueChange={onValueChange}
        onModalOpen={onModalOpen} // Pass the onModalOpen prop
        record={record}
        selectedValues={selectedValues}
        task={task}
      />
      <small className="text-gray-500">Current Value: {currentValue || ''}</small>
      {task.status !== 'completed' && (
        <div className="text-right text-xs text-gray-700 underline">
          <span className="cursor-pointer underline" onClick={onModalOpen}>
            None of the above?
          </span>
        </div>
      )}
    </div>
  )
}

const DropdownComponent = ({ documents }: { documents: any[] }) => (
  <div className="absolute right-4 top-4">
    <Dropdown
      color="gray"
      label={<span className="flex items-center gap-1 text-xs">Ref Doc ({documents.length})</span>}
    >
      {documents.map((doc) => (
        <Dropdown.Item key={doc.id} onClick={() => window.open(`#/document/${doc.id}`, '_blank')}>
          <span className="flex items-center gap-1">
            <Icon type="document" />
            <span className="uppercase">{doc.type ? getDocumentTypeDisplayName(doc.type) : 'Unknown Type'}</span>
          </span>
        </Dropdown.Item>
      ))}
    </Dropdown>
  </div>
)

const ValueOptions = ({
  options,
  currentValue,
  onValueChange,
  onModalOpen,
  record,
  selectedValues,
  task,
}: {
  options: { value: string; label: string; custom?: boolean }[]
  currentValue: string
  onValueChange: (value: string) => void
  onModalOpen: () => void
  record: any
  selectedValues: any
  task: any
}) => (
  <div className="values my-4 flex flex-wrap items-center justify-center gap-4">
    {options.map((option) => {
      // Separate the label (text before `: `) and value (text after `: `)
      const [label, value] = option.label.split(':').map((str) => str.trim())
      const isItemHighlighted =
        option.value &&
        ((record.field_name in selectedValues && selectedValues[record.field_name] === option.value) ||
          (record.status === 'overridden' && currentValue === option.value))
      return (
        <label
          key={option.value}
          className={twMerge([
            'rounded-lg border py-1 text-sm',
            isItemHighlighted ? 'bg-black text-white' : 'bg-gray-50',
            task.status === 'completed' ? 'cursor-not-allowed' : 'cursor-pointer hover:bg-black hover:text-white',
          ])}
        >
          <input
            type="radio"
            name="value"
            value={option.value}
            className="peer sr-only"
            checked={record.status === 'overridden' && currentValue === option.value}
            disabled={task.status === 'completed'}
            onChange={() => {
              onValueChange(option.value) // Update the selected value
              if (option.custom) {
                onModalOpen() // Open the modal if the option is custom
              }
            }}
          />
          <span
            className="flex items-center gap-2 rounded-lg px-2 py-1"
            onClick={() => {
              if (option.custom) {
                onModalOpen() // Ensure the modal opens when the custom button is clicked
              }
            }}
          >
            {option.custom ? (
              <>
                <span>{label}</span>
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="fill-black"
                >
                  <g clipPath="url(#clip0_184_14968)">
                    <path
                      d="M9.38251 3.09034C9.8793 2.5521 10.1277 2.28297 10.3916 2.12599C11.0285 1.74721 11.8128 1.73543 12.4603 2.09492C12.7286 2.24391 12.9847 2.50545 13.4967 3.02855C14.0088 3.55164 14.2648 3.81319 14.4107 4.08732C14.7626 4.74879 14.7511 5.5499 14.3803 6.20051C14.2266 6.47015 13.9631 6.72389 13.4362 7.23138L7.16713 13.2696C6.16862 14.2313 5.66937 14.7122 5.04541 14.9559C4.42145 15.1996 3.73551 15.1816 2.36362 15.1458L2.17696 15.1409C1.75931 15.13 1.55049 15.1245 1.4291 14.9867C1.30771 14.849 1.32428 14.6363 1.35743 14.2108L1.37542 13.9798C1.46871 12.7824 1.51536 12.1837 1.74918 11.6455C1.983 11.1073 2.38633 10.6704 3.19299 9.79637L9.38251 3.09034Z"
                      stroke="white"
                      strokeWidth="1.2"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.66669 3.16669L13.3334 7.83335"
                      stroke="white"
                      strokeWidth="1.2"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.33337 15.1667L14.6667 15.1667"
                      stroke="white"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_184_14968">
                      <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                    </clipPath>
                  </defs>
                </svg>
              </>
            ) : (
              <>
                <span className="uppercase">{getDocumentTypeDisplayName(label)}:</span>
                {value}
              </>
            )}
          </span>
        </label>
      )
    })}
  </div>
)

const CloseButton = ({ onClick }: { onClick: () => void }) => (
  <button
    onClick={onClick}
    className="absolute right-5 top-[18px] ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
  >
    <svg aria-hidden fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" className="h-5 w-5">
      <path
        fillRule="evenodd"
        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
    <span className="sr-only">Close modal</span>
  </button>
)

const CorrectValueModal = ({
  showModal,
  onClose,
  fieldName,
  customValue,
  setCustomValue,
  onSubmit,
}: {
  showModal: boolean
  onClose: () => void
  fieldName: string | null
  customValue: string
  setCustomValue: (value: string) => void
  onSubmit: () => void
}) => (
  <Modal onClose={onClose} show={showModal} size="xs" position="center" className="custom-modal">
    <Modal.Body className="relative rounded-lg bg-white p-4 shadow sm:p-5 dark:bg-gray-800">
      <div className="mb-4 flex items-center justify-between rounded-t border-b pb-4 sm:mb-5 dark:border-gray-600">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Enter Correct Value</h3>
        <CloseButton onClick={onClose} />
      </div>
      <div className="flex flex-col items-center">
        <p className="mb-2 text-gray-800">{fieldName ? formatString(fieldName) : 'Field'}</p>
        <input
          type="text"
          value={customValue}
          onChange={(e) => setCustomValue(e.target.value)}
          placeholder="Enter value"
          className="w-full rounded-lg border border-gray-300 p-2 text-sm focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
        />
      </div>
    </Modal.Body>
    <Modal.Footer className="justify-end">
      <button
        onClick={onSubmit}
        disabled={customValue.trim() === ''} // Disable button if input is empty
        className={`rounded-lg border border-gray-200 px-4 py-2 text-white ${
          customValue.trim() === '' ? 'cursor-not-allowed bg-gray-300' : 'bg-black hover:bg-gray-700'
        }`}
      >
        Save
      </button>
    </Modal.Footer>
  </Modal>
)

export default TaskDiscrepancy
