import React from 'react'

export function DocumentSearch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="32" height="32" rx="16" fill="#FDE8E8" />
      <path
        d="M15.4905 15.8904C15.2274 15.8902 14.9683 15.9549 14.7361 16.0788C14.504 16.2027 14.306 16.3819 14.1597 16.6006C14.0133 16.8193 13.9232 17.0707 13.8972 17.3325C13.8713 17.5944 13.9103 17.8586 14.0108 18.1018C14.1114 18.3449 14.2703 18.5595 14.4736 18.7266C14.6769 18.8936 14.9183 19.008 15.1763 19.0595C15.4343 19.111 15.7011 19.098 15.9529 19.0218C16.2048 18.9455 16.4339 18.8084 16.6201 18.6224C16.8433 18.3985 16.9951 18.1135 17.0565 17.8034C17.1179 17.4933 17.086 17.1719 16.9649 16.8799C16.8438 16.5879 16.6389 16.3383 16.376 16.1626C16.1132 15.9869 15.8042 15.893 15.4881 15.8928L15.4905 15.8904Z"
        fill="#9B1C1C"
      />
      <path
        d="M13.6002 12V8.104C13.2101 8.21264 12.8541 8.41886 12.5658 8.7032L10.3035 10.9656C10.0191 11.2539 9.81292 11.6099 9.70428 12H13.6002Z"
        fill="#9B1C1C"
      />
      <path
        d="M20.8528 8H15.2001V12C15.2001 12.4243 15.0316 12.8313 14.7315 13.1314C14.4315 13.4314 14.0245 13.6 13.6002 13.6H9.60028V22.4C9.59386 22.8172 9.75312 23.2199 10.0431 23.5198C10.3332 23.8197 10.7303 23.9924 11.1474 24H20.8528C21.2699 23.9924 21.667 23.8197 21.957 23.5198C22.2471 23.2199 22.4063 22.8172 22.3999 22.4V9.6C22.4063 9.18282 22.2471 8.78012 21.957 8.48019C21.667 8.18026 21.2699 8.00758 20.8528 8ZM19.4752 21.4752C19.3252 21.6252 19.1217 21.7094 18.9096 21.7094C18.6975 21.7094 18.494 21.6252 18.344 21.4752L17.1065 20.24C16.6183 20.5335 16.0601 20.6899 15.4905 20.6928C14.8576 20.6928 14.239 20.5051 13.7127 20.1535C13.1865 19.8019 12.7764 19.3021 12.5342 18.7174C12.292 18.1327 12.2286 17.4893 12.3521 16.8685C12.4756 16.2478 12.7803 15.6776 13.2278 15.2301C13.6754 14.7825 14.2455 14.4778 14.8662 14.3543C15.487 14.2308 16.1304 14.2942 16.7151 14.5364C17.2998 14.7786 17.7995 15.1887 18.1511 15.715C18.5027 16.2412 18.6904 16.8599 18.6904 17.4928C18.6875 18.0624 18.5311 18.6206 18.2376 19.1088L19.4752 20.3464C19.6244 20.4963 19.7082 20.6993 19.7082 20.9108C19.7082 21.1223 19.6244 21.3253 19.4752 21.4752Z"
        fill="#9B1C1C"
      />
    </svg>
  )
}
