import React from 'react'

export function Clock(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7 0C5.61553 0 4.26215 0.410543 3.11101 1.17971C1.95987 1.94888 1.06266 3.04213 0.532846 4.32121C0.00303299 5.6003 -0.13559 7.00776 0.134506 8.36563C0.404603 9.7235 1.07129 10.9708 2.05026 11.9497C3.02922 12.9287 4.2765 13.5954 5.63437 13.8655C6.99224 14.1356 8.3997 13.997 9.67879 13.4672C10.9579 12.9373 12.0511 12.0401 12.8203 10.889C13.5895 9.73784 14 8.38447 14 7C13.998 5.14411 13.2598 3.36482 11.9475 2.0525C10.6352 0.74019 8.85589 0.00203812 7 0ZM9.7874 9.7874C9.65613 9.91863 9.47812 9.99235 9.2925 9.99235C9.10689 9.99235 8.92887 9.91863 8.7976 9.7874L6.5058 7.4956C6.37486 7.36378 6.30095 7.18579 6.3 7V4.2C6.3 4.01435 6.37375 3.8363 6.50503 3.70502C6.6363 3.57375 6.81435 3.5 7 3.5C7.18565 3.5 7.3637 3.57375 7.49498 3.70502C7.62625 3.8363 7.7 4.01435 7.7 4.2V6.7102L9.7874 8.7976C9.91863 8.92887 9.99235 9.10688 9.99235 9.2925C9.99235 9.47811 9.91863 9.65613 9.7874 9.7874Z"
        fill="currentColor"
      />
    </svg>
  )
}
