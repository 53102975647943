import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
  type DragEndEvent,
} from '@dnd-kit/core'
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers'
import { arrayMove, horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import {
  ArrowDownIcon,
  ArrowsUpDownIcon,
  ArrowUpIcon,
  EllipsisVerticalIcon,
  FunnelIcon,
} from '@heroicons/react/24/outline'
import {
  Cell,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Header,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { QueryDocumentSnapshot } from 'firebase/firestore'
import React, { CSSProperties, useEffect, useLayoutEffect, useRef, useState } from 'react'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import clsx from 'clsx'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { PaginatedQueryRequest } from '../firebase/model'
import ActiveFilters from './active-filters'
import { Button } from './button'
import { DataTableFooter } from './data-table-footer'
import { DataTableShowColumns } from './data-table-show-columns'
import FilterDrawer from './filters'
import Toaster from './toaster'

export interface DataTableProps {
  columns: any
  data: any
  setColumns: any
  totalPages: number
  firstToken: QueryDocumentSnapshot
  lastToken: QueryDocumentSnapshot
  loading: false
  buttonGroup?: React.ReactElement
  searchQuery: PaginatedQueryRequest
  filter?: any
  setFilter?: any
  countText?: string
  totalCountText?: string
  setSearchQuery: (data: any) => void
  handleApply: (data: any) => void
}

const DraggableTableHeader = ({
  header,
  query,
  showToaster,
}: {
  header: Header<unknown, unknown>
  query: PaginatedQueryRequest
  showToaster: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const { attributes, isDragging, listeners, setNodeRef, transform } = useSortable({
    id: header.column.id,
  })
  const style: CSSProperties = {
    opacity: isDragging ? 0.8 : 1,
    position: 'relative',
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: 'width transform 0.2s ease-in-out',
    whiteSpace: 'nowrap',
    zIndex: isDragging ? 1 : 0,
    minWidth: header.column.getSize(),
    // width: header.column.getSize(),
  }

  return (
    <th
      colSpan={header.colSpan}
      ref={setNodeRef}
      style={style}
      className={`truncate px-5 py-3 text-left text-sm font-semibold text-gray-900 ${
        header.column.getIsResizing() ? 'isResizing' : ''
      } ${header.column.getCanSort() ? 'cursor-pointer' : ''}`}
      onClick={header.column.getToggleSortingHandler()}
    >
      <div className="flex items-center justify-between">
        <div className={clsx(`flex items-center gap-2`)}>
          {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
          {typeof header.column.columnDef.enableSorting === 'undefined'
            ? ({
                asc: <ArrowUpIcon height={15} width={15} />,
                desc: <ArrowDownIcon height={15} width={15} />,
              }[header.column.getIsSorted() as string] ?? (
                <ArrowsUpDownIcon
                  height={15}
                  width={15}
                  onClick={(e) => {
                    if (query.filter.length) {
                      showToaster(true)
                    }
                  }}
                />
              ))
            : null}
        </div>
        <button {...attributes} {...listeners} className="mr-2">
          {/* <ArrowsPointingOutIcon height={15} width={15} className="rotate-45" /> */}
          <EllipsisVerticalIcon height={15} width={15} />
        </button>
        {header.column.getCanResize() && (
          <div
            onMouseDown={header.getResizeHandler()}
            onTouchStart={header.getResizeHandler()}
            className={`resizer ${header.column.getIsResizing() ? 'isResizing z-[10000] !w-full' : ''}`}
            onClick={(e) => e.stopPropagation()}
          ></div>
        )}
      </div>
    </th>
  )
}

const CellSkelton = (
  <td className="truncate whitespace-nowrap p-2 text-sm text-gray-500">
    <Skeleton className="h-[25px]" direction="ltr" />
  </td>
)

const DragAlongCell = ({ cell, loading }: { cell: Cell<unknown, unknown>; loading: boolean }) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: cell.column.id,
  })

  const style: CSSProperties = {
    opacity: isDragging ? 0.8 : 1,
    position: 'relative',
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition: 'width transform 0.2s ease-in-out',
    // width: cell.column.getSize(),
    zIndex: isDragging ? 1 : 0,
    maxWidth: cell.column.getSize(),
  }

  return (
    <td style={style} ref={setNodeRef} className="truncate whitespace-nowrap p-3 text-sm text-gray-500">
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  )
}

const DataTable = ({
  columns,
  data,
  setColumns,
  totalPages,
  firstToken,
  lastToken,
  loading,
  filter,
  setFilter,
  buttonGroup,
  searchQuery,
  totalCountText,
  handleApply,
  setSearchQuery,
}: DataTableProps) => {
  const [showToasterSortDisabled, setShowToasterSortDisabled] = useState(false)
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: 10,
  })
  const [sorting, setSorting] = useState<SortingState>([])
  const tableContainer = useRef<HTMLDivElement>(null)

  const isProduction = process.env.REACT_APP_STAGE === 'production'

  useEffect(() => {
    if (searchQuery.filter.length) {
      setPagination((prevState) => ({
        ...prevState,
        pageIndex: 1,
      }))
      setSorting([])
    }
  }, [searchQuery.filter])

  useEffect(() => {
    if (sorting.length) {
      const sortingState = sorting[0]
      const col = columns?.filter((col: any) => col.id === sorting[0].id)?.[0] || {}
      setSearchQuery((prevQuery: PaginatedQueryRequest) => ({
        ...prevQuery,
        sort: {
          key: col?.key || '',
          value: sortingState.desc ? 'desc' : 'asc',
        },
        direction: '',
        pageToken: '',
      }))
      setPagination((prevState) => ({
        ...prevState,
        pageIndex: 1,
      }))
    }
  }, [sorting])

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event
    if (active && over && active.id !== over.id) {
      setColumnOrder((columnOrder) => {
        const oldIndex = columnOrder.indexOf(active.id as string)
        const newIndex = columnOrder.indexOf(over.id as string)
        return arrayMove(columnOrder, oldIndex, newIndex) //this is just a splice util
      })
    }
  }

  const sensors = useSensors(useSensor(MouseSensor, {}), useSensor(TouchSensor, {}), useSensor(KeyboardSensor, {}))

  const [columnVisibility, setColumnVisibility] = useState({})
  const [columnOrder, setColumnOrder] = useState<string[]>(() => [...columns].map((c: any) => c.id!))

  const table = useReactTable({
    columns: [...columns],
    data,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',
    rowCount: pagination.pageSize,
    enableSortingRemoval: false,
    manualSorting: true,
    state: {
      sorting,
      columnVisibility,
      columnOrder,
    },
    pageCount: 10,
    defaultColumn: {
      minSize: 60,
    },
    enableSorting: !searchQuery?.filter?.length,
    onColumnOrderChange: setColumnOrder,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onColumnVisibilityChange: setColumnVisibility,
    getPaginationRowModel: getPaginationRowModel(),
  })

  const availableCols = table?.getAllLeafColumns()?.filter((c) => c.getIsVisible())

  const expanded = useSelector((state: any) => state.sidebarReducer)

  useLayoutEffect(() => {
    if (table && tableContainer?.current?.clientWidth) {
      const len = table?.getAllLeafColumns()?.filter((c) => c.getIsVisible()).length

      const cols = [...columns]
      let totalSize = 0
      for (const element of table?.getAllLeafColumns()) {
        if (element.getIsVisible()) {
          let col = columns?.filter((c: any) => c.id === element.id)
          totalSize += col?.[0]?.initialSize
        }
      }
      availableCols?.map((col) => {
        cols?.map((_c) => {
          if (col.id === _c.id) {
            _c.size =
              totalSize > (tableContainer?.current?.clientWidth || 0)
                ? _c.initialSize
                : +(tableContainer?.current?.clientWidth || 0) / len
          }
          return null
        })
        return null
      })
      setColumns(cols)
    }
  }, [tableContainer?.current?.clientWidth, columnVisibility, expanded.isExpanded])

  const rows = table.getRowModel().rows

  const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false)

  const getInitialState = () => {
    const filterState: any = { ...filter }
    columns?.map((col: any) => {
      if (col?.filter && !filterState?.[col.id]) {
        filterState[col.id] = {
          operation: '',
          value: null,
          key: col.filter.accessor,
        }
      }
    })
    return filterState
  }

  useEffect(() => {
    if (columns && filter) {
      const filterState = getInitialState()
      setFilter(filterState)
    }
  }, [columns])

  const handleResetFilter = (filterKey?: string) => {
    const filterObj = { ...filter }
    for (const key in filterObj) {
      if (Object.prototype.hasOwnProperty.call(filterObj, key)) {
        const element = filterObj[key]
        if (element.key === filterKey) {
          element.value = null
          element.operation = ''
        } else if (!filterKey) {
          element.value = null
          element.operation = ''
        }
      }
    }
    setFilter(filterObj)
    handleApply(filterObj)
  }

  return (
    <>
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToHorizontalAxis]}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <div className="mt-10 flex items-start justify-between">
          <div className="flex flex-wrap gap-x-2 gap-y-2">
            {!isProduction && (
              <ActiveFilters filters={searchQuery?.filter} columns={columns} handleResetFilter={handleResetFilter} />
            )}
          </div>
          <div className="flex items-center gap-3">
            {filter && !isProduction ? (
              <Button className="!px-4" onClick={() => setFilterDrawerOpen(true)}>
                <FunnelIcon width={20} height={20} className="!text-white" />
                {!loading && searchQuery?.filter?.length ? (
                  <span className="absolute right-[2px] top-[4px] flex h-[16px] w-[16px] items-center justify-center rounded-[50%] bg-white text-[12px] text-zinc-950">
                    {searchQuery?.filter?.length}
                  </span>
                ) : null}
                Filters
              </Button>
            ) : null}
            {buttonGroup ? buttonGroup : null}
            <DataTableShowColumns table={table} />
          </div>
        </div>
        <div className="mt-4">
          <div className="overflow-hidden sm:-mx-6 lg:-mx-6" ref={tableContainer}>
            <div className="flow-root min-w-full py-2 align-middle sm:px-6 lg:px-6">
              <div className="!overflow-x-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="block min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        <SortableContext items={columnOrder} strategy={horizontalListSortingStrategy}>
                          {headerGroup.headers.map((header, index) => {
                            return (
                              <DraggableTableHeader
                                key={header.id}
                                header={header}
                                query={searchQuery}
                                showToaster={setShowToasterSortDisabled}
                              />
                            )
                          })}
                        </SortableContext>
                      </tr>
                    ))}
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {!loading && rows?.length > 0 ? (
                      rows.map((row) => {
                        return (
                          <tr key={row.id} className="cursor-pointer hover:bg-[#f1f5f9]">
                            {row.getVisibleCells().map((cell) => {
                              return (
                                <>
                                  <SortableContext
                                    key={cell.id}
                                    items={columnOrder}
                                    strategy={horizontalListSortingStrategy}
                                  >
                                    <DragAlongCell key={cell.id} cell={cell} loading={loading} />
                                  </SortableContext>
                                </>
                              )
                            })}
                          </tr>
                        )
                      })
                    ) : !loading ? (
                      <tr>
                        <td
                          colSpan={table.getHeaderGroups()[0]?.headers?.length || 1}
                          className="p-3 text-sm text-gray-500"
                        >
                          <span className="ml-3 font-semibold uppercase italic"> No data matching the filter.</span>
                        </td>
                      </tr>
                    ) : (
                      [...Array(pagination.pageSize)].map((_, index) => {
                        return (
                          <tr key={`skelton_${index}`} className="cursor-pointer hover:bg-[#f1f5f9]">
                            {availableCols.map(() => CellSkelton)}
                          </tr>
                        )
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <DataTableFooter
            pagination={pagination}
            firstToken={firstToken}
            lastToken={lastToken}
            loading={loading}
            totalPages={totalPages}
            rowCount={table.getRowCount()}
            totalCountText={totalCountText}
            setPagination={setPagination}
            setSearchQuery={setSearchQuery}
            setPageSize={(size) => table.setPageSize(size)}
            totalCount={0}
          />
        </div>
      </DndContext>
      {filter && !isProduction ? (
        <FilterDrawer
          open={isFilterDrawerOpen}
          filters={filter}
          searchQuery={searchQuery}
          columns={table.getAllLeafColumns()}
          setOpen={setFilterDrawerOpen}
          setFilters={setFilter}
          setSearchQuery={setSearchQuery}
          handleApply={handleApply}
          resetHandler={handleResetFilter}
        />
      ) : null}
      <Toaster
        show={showToasterSortDisabled}
        setShow={setShowToasterSortDisabled}
        variant={'error'}
        title={'Sorting is disabled on active filters!'}
      />
    </>
  )
}

export default DataTable
