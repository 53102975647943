import { Button } from 'flowbite-react'
import React, { useRef, useState } from 'react'
import { Task } from '../hooks/useTaskDetails'
import ButtonModal from './ButtonModal'

interface Props {
  task: Task
}

const EmailModalButton: React.FC<Props> = ({ task }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  return (
    <div className="relative">
      <Button className="ml-auto gap-2 rounded-lg border border-gray-200 bg-white text-black" onClick={openModal}>
        <div className="flex items-center gap-2">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.8 1H1.2C0.88174 1 0.576515 1.14048 0.351472 1.39052C0.126428 1.64057 0 1.97971 0 2.33333V9.66667C0 10.0203 0.126428 10.3594 0.351472 10.6095C0.576515 10.8595 0.88174 11 1.2 11H10.8C11.1183 11 11.4235 10.8595 11.6485 10.6095C11.8736 10.3594 12 10.0203 12 9.66667V2.33333C12 1.97971 11.8736 1.64057 11.6485 1.39052C11.4235 1.14048 11.1183 1 10.8 1ZM10.4718 2.33333L6.0258 6.52267L1.5336 2.33333H10.4718ZM1.2 9.66667V3.76333L5.28 7.56333C5.48528 7.73489 5.73533 7.82734 5.9922 7.82667C6.26488 7.82547 6.52973 7.72527 6.7464 7.54133L10.8 3.76333V9.66667H1.2Z"
              fill="#111928"
            />
          </svg>
          <span className="text-xs">View Email</span>
        </div>
      </Button>

      <ButtonModal isOpen={isModalOpen} onClose={closeModal} buttonRef={buttonRef}>
        <div>
          <h2 className="text-[14px] font-semibold">{task.data.email_subject}</h2>
          <p className="text-xs text-gray-500">From: {task.data.email_sender}</p>
          <hr className="my-4" />
          <div className="text-[14px]">{task.data.email_body}</div>
        </div>
      </ButtonModal>
    </div>
  )
}

export default EmailModalButton
