import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

const initialState: any = {
  loader: false,
  data: null,
  json_data: {
    data: null,
    loader: true,
    error: null,
  },
  docSrc: '',
}

export const getDocumentData = createAsyncThunk('get/documentDetailsJson', async (url: string) => {
  const response = await axios.get(url)
  return response.data
})

const documentDetails = createSlice({
  name: 'shipmentsDetails',
  initialState,
  reducers: {
    updateGetDocumentLoader(state) {
      state.loader = !state.loader
    },
    getDocument(state, action) {
      state.loader = false
      state.data = action.payload
    },
    updateDocSrc(state, action) {
      state.docSrc = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDocumentData.pending, (state) => {
      state.json_data.loader = true
    })
    builder.addCase(getDocumentData.fulfilled, (state, action) => {
      state.json_data.loader = false
      state.json_data.data = action.payload
      state.json_data.error = null
    })
    builder.addCase(getDocumentData.rejected, (state, action) => {
      state.json_data.loader = false
      state.json_data.error = action.payload
      state.json_data.data = null
    })
  },
})

export const { updateGetDocumentLoader, getDocument, updateDocSrc } = documentDetails.actions
export default documentDetails.reducer
