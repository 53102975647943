import { fetchDocumentById } from 'shared/firebase/query/document'
import { listenShipmentDetails } from 'shared/firebase/query/shipments'
import { allTasksListener } from 'shared/firebase/query/tasks'

export const updateShipmentAndDocumentDetails = ({
  task,
  orgID,
  shipmentCacheRef,
  setShipmentDetails,
  setDocumentTypes,
  unsubscribeList,
}: {
  task: any
  orgID: string
  shipmentCacheRef: React.MutableRefObject<Record<string, any>>
  setShipmentDetails: React.Dispatch<React.SetStateAction<Record<string, any>>>
  setDocumentTypes: React.Dispatch<React.SetStateAction<Record<string, { id: string; type: string }>>>
  unsubscribeList: Array<() => void>
}) => {
  if (!task.dependent_task_ids?.[0]) return

  const dependentTaskId = task.dependent_task_ids[0]

  const unsubscribeDependentTask = allTasksListener(
    { orgId: orgID, pageSize: 1, filter: [] },
    ({ tasks }: { tasks: Array<any> }) => {
      const dependentTask = tasks.find((t) => t.id === dependentTaskId)

      if (dependentTask?.data?.shipment_ids?.[0] && !shipmentCacheRef.current[task.id]) {
        const unsubscribe = listenShipmentDetails(
          { orgId: orgID, shipmentId: dependentTask.data.shipment_ids[0], refetch: 0 },
          (shipment) => {
            // Store the entire shipment object
            shipmentCacheRef.current[task.id] = shipment
            setShipmentDetails((prev) => ({ ...prev, [task.id]: shipment }))
          }
        )
        unsubscribeList.push(unsubscribe)
      }

      if (dependentTask?.data?.shipping_doc_type) {
        setDocumentTypes((prev) => ({
          ...prev,
          [task.id]: {
            type: dependentTask?.data?.shipping_doc_type,
            id: dependentTask?.data?.shipping_document_id || dependentTask?.data?.original_document_id,
          },
        }))
      } else if (dependentTask?.data?.shipping_document_id) {
        fetchDocumentById({ orgId: orgID, docId: dependentTask.data.shipping_document_id }, (document: any) => {
          setDocumentTypes((prev) => ({
            ...prev,
            [task.id]: { type: document?.type || 'Unknown', id: document?.id },
          }))
        })
      }
    }
  )

  unsubscribeList.push(unsubscribeDependentTask)
}
