import XCircleIcon from '@heroicons/react/24/outline/XCircleIcon'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Label } from '../../components/fieldset'
import { Input } from '../../components/input'
import errorCodes from '../../content/error-codes.json'
import { handleLogin } from '../../reduxStore/slices/auth/authSlice'
import { AppDispatch } from '../../reduxStore/store'
// import { handleLogin } from '../../reduxStore/slices/auth/authSlice'

type Inputs = {
  username: string
  password: string
}

export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()
  const dispatch = useDispatch<AppDispatch>()
  const { error } = useSelector((state: any) => state.authReducer)

  const onSubmit = (data: any) => {
    dispatch(handleLogin({ username: data.username, password: data.password }))
  }

  return (
    <>
      <div className="flex min-h-[100vh] flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img src="/static/images/Transparent-01.png" alt="Brand logo" width={200} height={30} />
              <h2 className="mt-8 text-lg font-bold leading-9 tracking-tight text-gray-900">Sign in to your account</h2>
            </div>

            <div className="mt-10">
              <div>
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                  <Field>
                    <Label>Username</Label>
                    <Input
                      type="text"
                      {...register('username', {
                        required: 'Username is required',
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: 'Please enter a valid email',
                        },
                      })}
                      invalid={!!errors.username || error !== null}
                      className="!mt-1"
                    />
                    {errors.username && <Label className="!text-red-500">{errors.username?.message}</Label>}
                  </Field>
                  <Field className="!mt-2">
                    <Label>Password</Label>
                    <Input
                      type="password"
                      className="!mt-1"
                      {...register('password', {
                        required: 'Password is required',
                      })}
                      invalid={!!errors.password || error !== null}
                    />
                    {errors.password && <Label className="!text-red-500">{errors.password?.message}</Label>}
                  </Field>
                  {error ? (
                    <div className="rounded-md bg-red-50 p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <XCircleIcon aria-hidden="true" className="h-5 w-5 text-red-400" />
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-red-800">
                            {errorCodes?.[error?.message as keyof typeof errorCodes]}
                          </h3>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md bg-zinc-950 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Sign in
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            alt=""
            src="https://images.squarespace-cdn.com/content/v1/665e226dbef91d56b1ebb7d0/d9ab9ac4-6a82-4af3-b2f3-70a07fa1f750/freight-mate-ai-workflow-automation.png"
            className="absolute inset-0 h-full w-full object-cover"
          />
        </div>
      </div>
    </>
  )
}
