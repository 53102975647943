import React from 'react'

export function LayersOutLine(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.19885 5.96623L8.5492 9.99597C8.68939 10.0722 8.84501 10.112 9.00296 10.112C9.16091 10.112 9.31654 10.0722 9.45672 9.99597L16.8071 5.96623C16.9656 5.87932 17.0984 5.7486 17.191 5.58825C17.2837 5.4279 17.3327 5.24403 17.3327 5.05659C17.3327 4.86915 17.2837 4.68528 17.191 4.52493C17.0984 4.36458 16.9656 4.23386 16.8071 4.14695L9.45672 0.117211C9.31641 0.0402186 9.16038 0 9.00198 0C8.84359 0 8.68755 0.0402186 8.54724 0.117211L1.19689 4.14695C1.03836 4.23386 0.905572 4.36458 0.812917 4.52493C0.720262 4.68528 0.671279 4.86915 0.671279 5.05659C0.671279 5.24403 0.720262 5.4279 0.812917 5.58825C0.905572 5.7486 1.03836 5.87932 1.19689 5.96623H1.19885ZM9.00296 2.18598L14.2393 5.0607L9.00296 7.93541L3.76657 5.0607L9.00296 2.18598Z"
        fill="currentColor"
      />
      <path
        d="M16.3533 8.16642C16.0934 8.16642 15.8441 8.27458 15.6603 8.46713C15.4765 8.65967 15.3733 8.92081 15.3733 9.1931V9.37893L9.00296 12.8697L2.63266 9.37893V9.1931C2.63266 8.92081 2.5294 8.65967 2.34561 8.46713C2.16182 8.27458 1.91254 8.16642 1.65261 8.16642C1.39269 8.16642 1.14341 8.27458 0.959616 8.46713C0.775822 8.65967 0.672567 8.92081 0.672567 9.1931V10.0001C0.672538 10.1875 0.721475 10.3713 0.814074 10.5317C0.906672 10.692 1.0394 10.8228 1.19787 10.9097L8.54822 14.9395C8.68853 15.0164 8.84457 15.0567 9.00296 15.0567C9.16136 15.0567 9.31739 15.0164 9.4577 14.9395L16.8081 10.9097C16.9665 10.8228 17.0992 10.692 17.1918 10.5317C17.2844 10.3713 17.3334 10.1875 17.3334 10.0001V9.1931C17.3334 8.92081 17.2301 8.65967 17.0463 8.46713C16.8625 8.27458 16.6132 8.16642 16.3533 8.16642Z"
        fill="currentColor"
      />
      <path
        d="M16.3474 13.1099C16.0875 13.1099 15.8382 13.2181 15.6544 13.4106C15.4706 13.6032 15.3674 13.8643 15.3674 14.1366V14.3224L8.99708 17.8131L2.62678 14.3224V14.1366C2.62678 13.8643 2.52352 13.6032 2.33973 13.4106C2.15594 13.2181 1.90666 13.1099 1.64673 13.1099C1.38681 13.1099 1.13753 13.2181 0.953736 13.4106C0.769942 13.6032 0.666687 13.8643 0.666687 14.1366V14.9446C0.666658 15.132 0.715594 15.3158 0.808193 15.4762C0.900792 15.6365 1.03352 15.7673 1.19199 15.8542L8.54234 19.884C8.68287 19.9602 8.83882 20 8.99708 20C9.15534 20 9.31129 19.9602 9.45182 19.884L16.8022 15.8542C16.9606 15.7673 17.0934 15.6365 17.186 15.4762C17.2786 15.3158 17.3275 15.132 17.3275 14.9446V14.1366C17.3275 13.8643 17.2242 13.6032 17.0404 13.4106C16.8566 13.2181 16.6074 13.1099 16.3474 13.1099Z"
        fill="currentColor"
      />
    </svg>
  )
}
