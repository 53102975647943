import React from 'react'

export function FileCheckOutline(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.3187 0H7.73749C6.90311 0 6.1177 0.312 5.52811 0.879L2.58228 3.707C1.99166 4.273 1.66666 5.027 1.66666 5.829V18C1.66666 19.103 2.56978 20 3.68124 20H16.3198C17.4302 20 18.3333 19.103 18.3333 18V2C18.3333 0.897 17.4302 0 16.3187 0ZM6.87499 2.414V5H4.18124L6.87499 2.414ZM3.74999 18V7H6.87499C8.02395 7 8.95832 6.103 8.95832 5V1.996L16.2437 1.974C16.2437 1.974 16.25 1.982 16.25 2L16.3187 18H3.74999Z"
        fill="currentColor"
      />
      <path
        d="M12.3885 9.293L8.95832 12.586L7.61145 11.293C7.20416 10.902 6.54582 10.902 6.13853 11.293C5.73124 11.684 5.73124 12.316 6.13853 12.707L8.22186 14.707C8.42499 14.902 8.69166 15 8.95832 15C9.22499 15 9.49166 14.902 9.69478 14.707L13.8614 10.707C14.2687 10.316 14.2687 9.684 13.8614 9.293C13.4542 8.902 12.7958 8.902 12.3885 9.293Z"
        fill="currentColor"
      />
    </svg>
  )
}
