import { WhereFilterOp } from 'firebase/firestore'
import React, { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'

export type Value = {
  label: string
  value: string
}

export type Request = {
  key: string
  value: Value[]
  operation: WhereFilterOp
}

export type ActionCardType = Array<{
  id: string
  title: string
  value: number
  isActive: boolean
  request?: Request
}>

export interface ActionCardsProps {
  cards: ActionCardType
  loading?: boolean
  filter?: {
    [key: string]: {
      value: string | string[] | null
      operation: string
      key: string
    }
  }
  filterKey?: string
  setCards: (card: ActionCardType) => void
  setFilter: (filters: any) => void
  applyFilters: (filters: any) => void
}

export const ActionCards = ({
  cards,
  loading,
  filter = {},
  filterKey = '',
  setCards,
  setFilter,
  applyFilters,
}: ActionCardsProps) => {
  useEffect(() => {
    const filterData = filter?.[filterKey]
    if (filterData) {
      const _cards = [...cards]?.map((_card) => {
        const a = [...(filterData.value || [])]?.sort((a: any, b: any) => a.value.localeCompare(b.value))
        const b = [...(_card.request?.value || [])]?.sort((a: any, b: any) => a.value.localeCompare(b.value))
        return {
          ..._card,
          isActive:
            filterData.value && _card.request?.value
              ? JSON.stringify(a) === JSON.stringify(b) && filterData.operation === _card.request.operation
              : false,
        }
      })
      setCards(_cards)
    }
  }, [filter])

  const handleClick = (card: any) => {
    if (filter) {
      const updatedFiltr = { ...filter }
      const req = { ...card.request }

      if (card?.request?.value && JSON.stringify(updatedFiltr[filterKey]) !== JSON.stringify(card.request)) {
        updatedFiltr[filterKey] = req
      } else {
        updatedFiltr[filterKey].value = null
        updatedFiltr[filterKey].operation = ''
      }

      setFilter(updatedFiltr)
      applyFilters(updatedFiltr)
    }
  }

  return (
    <dl className={'mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4'}>
      {cards?.map((card: any, index: number) => (
        <div
          className={`cursor-pointer overflow-hidden rounded-lg hover:text-white ${
            card?.isActive ? 'bg-zinc-950' : 'bg-white hover:bg-zinc-950/5'
          } px-4 py-5 shadow sm:p-6 ${!card?.request ? 'cursor-not-allowed' : ''}`}
          onClick={() => handleClick(card)}
          key={card?.name}
        >
          <dt className={`truncate text-sm font-medium ${card?.isActive ? 'text-white' : 'text-gray-500'}`}>
            {card?.title}
          </dt>
          {!loading ? (
            <dd
              className={`mt-1 text-3xl font-semibold tracking-tight ${card?.isActive ? 'text-white' : 'text-gray-900'}`}
            >
              {card.value}
            </dd>
          ) : (
            <Skeleton count={1} className="mt-1 text-3xl font-semibold tracking-tight" />
          )}
        </div>
      ))}
    </dl>
  )
}
