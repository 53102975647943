import { createSlice } from '@reduxjs/toolkit'

const initialState: any = {
  currentPageNumber: 0,
  totalPages: 0,
  shipments: [],
  firstToken: '',
  lastToken: '',
  isLoading: false,
  totalData: 0,
  error: null,
  stats: {
    needsAttention: 0,
    missingDocuments: 0,
    inProgress: 0,
    completed: 0,
    loading: false,
  },
}

const shipments = createSlice({
  name: 'shipments',
  initialState,
  reducers: {
    updateLoader(state) {
      state.isLoading = true
    },
    updateShipment(state, action) {
      state.shipments = action.payload.shipments
      state.totalPages = action.payload.totalPages
      state.firstToken = action.payload.firstToken
      state.lastToken = action.payload.lastToken
      state.totalData = action.payload.totalData
      state.error = null
      state.isLoading = false
    },
    updateStatsLoading(state) {
      state.stats.loading = true
    },
    updateShipmentStats(state, action) {
      state.stats = {
        ...action.payload,
        loading: false,
      }
    },
  },
})

export const { updateShipment, updateLoader, updateStatsLoading, updateShipmentStats } = shipments.actions
export default shipments.reducer
