import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useNavigate } from 'react-router-dom'
import { Task } from 'shared/hooks/useTaskDetails'
import { getDocumentTypeDisplayName, getTaskStatus, getTaskTypeDisplayName } from 'shared/services'
import { getDueDateClassName } from 'shared/utils'
import { updateShipmentAndDocumentDetails } from 'shared/utils/updateShipmentAndDocumentDetails'

interface Props {
  task: Task
}

const LoadingSkeleton = () => {
  const [showSkeleton, setShowSkeleton] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSkeleton(false)
    }, 2000) // Hide the skeleton after 2 seconds

    return () => clearTimeout(timer) // Clear the timer on component unmount
  }, [])

  return showSkeleton ? <Skeleton height="20px" direction="ltr" /> : null
}

const DetailsHeader: React.FC<Props> = ({ task }) => {
  const navigate = useNavigate()
  const [shipment, setShipment] = useState<any | null>(null)
  const [document, setDocument] = useState<any | null>(null)

  useEffect(() => {
    const unsubscribeList: Array<() => void> = []

    updateShipmentAndDocumentDetails({
      task,
      orgID: task.org_id,
      shipmentCacheRef: { current: {} },
      setShipmentDetails: (updateFn) => {
        setShipment((prevShipmentDetails: any) => {
          const updatedDetails = typeof updateFn === 'function' ? updateFn(prevShipmentDetails) : updateFn

          return updatedDetails[task.id] || null
        })
      },
      setDocumentTypes: (updateFn) => {
        setDocument((prevDocument: any) => {
          const updatedDocument = typeof updateFn === 'function' ? updateFn(prevDocument) : updateFn

          return updatedDocument[task.id] || null
        })
      },
      unsubscribeList,
    })

    return () => {
      unsubscribeList.forEach((unsubscribe) => unsubscribe())
    }
  }, [task, task.org_id])

  return (
    <div className="border-b border-gray-200 bg-white py-4">
      <div className="container mx-auto flex flex-wrap items-center justify-between">
        <div className="flex w-full justify-between gap-10">
          <div className="flex items-center gap-6">
            <button onClick={() => navigate(-1)}>
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer"
              >
                <path
                  d="M0.5 17C0.5 7.8873 7.8873 0.5 17 0.5C26.1127 0.5 33.5 7.8873 33.5 17C33.5 26.1127 26.1127 33.5 17 33.5C7.8873 33.5 0.5 26.1127 0.5 17Z"
                  fill="white"
                />
                <path
                  d="M0.5 17C0.5 7.8873 7.8873 0.5 17 0.5C26.1127 0.5 33.5 7.8873 33.5 17C33.5 26.1127 26.1127 33.5 17 33.5C7.8873 33.5 0.5 26.1127 0.5 17Z"
                  stroke="#E5E7EB"
                />
                <path
                  d="M15.2867 17.0002L20.2225 12.1704C20.5752 11.8248 20.5944 11.2446 20.2645 10.8743C19.9356 10.504 19.3799 10.4847 19.0282 10.8303L14.0399 15.7123C13.6916 16.0533 13.5 16.5098 13.5 17.0002C13.5 17.4906 13.6916 17.9471 14.039 18.2881L19.0273 23.1701C19.1962 23.3351 19.4106 23.4166 19.6249 23.4166C19.8594 23.4166 20.0922 23.3195 20.2637 23.1261C20.5935 22.7558 20.5743 22.1764 20.2217 21.8299L15.2867 17.0002Z"
                  fill="#1F2A37"
                />
              </svg>
            </button>

            <div>
              <h2 className="text-lg font-bold">{getTaskTypeDisplayName(task.type)}</h2>
              {shipment && shipment.client_shipment_id ? (
                <p className="text-sm text-gray-700">{shipment.client_shipment_id || 'Unassigned'}</p>
              ) : (
                <LoadingSkeleton />
              )}
            </div>
          </div>
          <div className="flex grow flex-wrap justify-start gap-6 text-sm text-gray-600">
            <div className="flex flex-col gap-1">
              <span className="text-xs">Status</span>
              <span className="rounded-full bg-gray-100 px-3 py-1 text-xs capitalize text-gray-900">
                {getTaskStatus(task.status)}
              </span>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-xs">Due Date</span>
              <span
                className={`flex items-center gap-1 ${getDueDateClassName(new Date(task.schedule?.due_at.seconds * 1000))}`}
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-1 inline-block"
                >
                  <g clipPath="url(#clip0_265_8349)">
                    <path
                      d="M7 0C5.61553 0 4.26215 0.410543 3.11101 1.17971C1.95987 1.94888 1.06266 3.04213 0.532846 4.32121C0.00303299 5.6003 -0.13559 7.00776 0.134506 8.36563C0.404603 9.7235 1.07129 10.9708 2.05026 11.9497C3.02922 12.9287 4.2765 13.5954 5.63437 13.8655C6.99224 14.1356 8.3997 13.997 9.67879 13.4672C10.9579 12.9373 12.0511 12.0401 12.8203 10.889C13.5895 9.73784 14 8.38447 14 7C13.998 5.14411 13.2598 3.36482 11.9475 2.0525C10.6352 0.74019 8.85589 0.00203812 7 0ZM9.7874 9.7874C9.65613 9.91863 9.47812 9.99235 9.2925 9.99235C9.10689 9.99235 8.92887 9.91863 8.7976 9.7874L6.5058 7.4956C6.37486 7.36378 6.30095 7.18579 6.3 7V4.2C6.3 4.01435 6.37375 3.8363 6.50503 3.70502C6.6363 3.57375 6.81435 3.5 7 3.5C7.18565 3.5 7.3637 3.57375 7.49498 3.70502C7.62625 3.8363 7.7 4.01435 7.7 4.2V6.7102L9.7874 8.7976C9.91863 8.92887 9.99235 9.10688 9.99235 9.2925C9.99235 9.47811 9.91863 9.65613 9.7874 9.7874Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_265_8349">
                      <rect width="14" height="14" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <span className="font-semibold">{new Date(task.schedule?.due_at.seconds * 1000).toLocaleString()}</span>
              </span>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-xs">Mode</span>
              {shipment && shipment.data.load_type ? (
                <span className="font-semibold text-gray-900">{shipment && shipment.data.load_type}</span>
              ) : (
                <LoadingSkeleton />
              )}
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-xs">Consignee</span>
              {shipment && shipment.data.consignee ? (
                <span className="font-semibold text-gray-900">{shipment && shipment.data.consignee.name}</span>
              ) : (
                <LoadingSkeleton />
              )}
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-xs">Assigned User</span>
              <span className="font-semibold text-black">{task.schedule?.assigned_to || 'Unassigned'}</span>
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-xs">Dest. Port</span>
              {shipment && shipment.data.port_of_discharge?.unloc ? (
                <span className="font-semibold text-gray-900">
                  {shipment && shipment.data.port_of_discharge?.unloc}
                </span>
              ) : (
                <LoadingSkeleton />
              )}
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-xs">Type</span>
              {document && (
                <a
                  href={`/#/document/${document.id}`}
                  target="_blank"
                  className="inline-flex items-center gap-1 rounded-lg border border-[#057A55] px-2 py-0 text-xs"
                  rel="noopener noreferrer"
                >
                  <span className="uppercase text-[#057A55]">{getDocumentTypeDisplayName(document.type)}</span>
                </a>
              )}
            </div>
            {/*{task.downloadUrl && (*/}
            {/*  <a*/}
            {/*    href={task.downloadUrl}*/}
            {/*    target="_blank"*/}
            {/*    rel="noopener noreferrer"*/}
            {/*    className="ml-auto rounded-lg border border-gray-200 bg-white px-4 py-2 text-black"*/}
            {/*  >*/}
            {/*    View Details*/}
            {/*  </a>*/}
            {/*)}*/}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailsHeader
