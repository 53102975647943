import { Dropdown, Navbar, theme } from 'flowbite-react'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { handleLogout } from 'shared/reduxStore/slices/auth/authSlice'
import { AppDispatch } from 'shared/reduxStore/store'
import { twMerge } from 'tailwind-merge'
import content from '../content/navbar.json'
import ChartPie from '../icons/ChartPie'
import Clipboard from '../icons/Clipboard'
import CreditCard from '../icons/CreditCard'
import Documents from '../icons/Documents'
import { Avatar } from './avatar'

export function DefaultDashboardNavbar({ prefix, portal = 'user' }: { prefix: string; portal?: 'user' | 'bpo' }) {
  const icons = {
    '/dashboard': <ChartPie />,
    '/documents': <Documents />,
    '/tasks': <CreditCard />,
    '/shipments': <Clipboard />,
  }
  const { pathname } = useLocation()

  const [isVisible, setIsVisible] = useState(true)
  const [lastScrollY, setLastScrollY] = useState(0)
  const dispatch = useDispatch<AppDispatch>()
  const user = useSelector((state: any) => state.authReducer)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        // Scrolling down
        setIsVisible(false)
      } else {
        // Scrolling up or at the top
        setIsVisible(true)
      }
      setLastScrollY(window.scrollY)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [lastScrollY])

  return (
    <header className="mb-12">
      <Navbar
        className={`fixed left-0 top-0 z-40 w-full border-b border-gray-200 bg-white transition-transform duration-300 ${isVisible ? 'translate-y-0 transform' : '-translate-y-full transform'}`}
      >
        <div className="flex w-full flex-wrap items-center">
          <div className="mr-6 flex items-center justify-start">
            <a href="/" className="mr-4 flex">
              <img src="/static/images/logo.svg" className="mr-3 h-8" alt="" />
            </a>
          </div>
          <div className="hidden w-full items-center justify-between lg:order-1 lg:flex lg:w-auto" id="mobile-menu-2">
            <ul className="mt-4 flex flex-col font-medium lg:mt-0 lg:flex-row lg:space-x-8">
              {content?.navitems?.map(
                (
                  item: { href: string; name: string; enabled: boolean; v2Enabled?: boolean; bpoEnabled?: boolean },
                  index
                ) => {
                  const Icon = icons[item?.href as keyof typeof icons]
                  if (portal === 'bpo' && !item?.bpoEnabled) return null // Check bpoEnabled and return null if false
                  const href = item?.v2Enabled ? `${prefix || ''}${item?.href}` : item?.href // Check v2Enabled and update href accordingly
                  return item?.enabled ? (
                    <li
                      key={index}
                      className={`${pathname === prefix + item?.href ? 'opacity-100' : 'opacity-50'} hover:opacity-100`}
                    >
                      <Link
                        to={href}
                        className="lg:text-primary-700 flex items-center gap-2 rounded py-2 pl-3 pr-4 text-sm lg:p-0"
                      >
                        {Icon}
                        {item?.name}
                      </Link>
                    </li>
                  ) : null
                }
              )}
            </ul>
          </div>
          <div className="ml-auto flex items-center gap-x-2 lg:order-2">
            <div className="w-[1px] self-stretch bg-gray-200"></div>
            <Dropdown
              inline
              label={
                <>
                  <span className="sr-only">Open user menu</span>
                  <span className="flex min-w-0 cursor-pointer items-center justify-center gap-3">
                    <Avatar src="static/images/avatar.svg" className="h-6 w-6 rounded-full" />
                    <span className="text-sm text-black">{user?.user?.email}</span>
                  </span>
                </>
              }
              placement="bottom"
              theme={{
                floating: {
                  base: twMerge(theme.dropdown.floating.base, 'w-60'),
                },
                inlineWrapper: twMerge(theme.dropdown.inlineWrapper, 'pl-2 text-gray-600 dark:text-gray-400'),
              }}
            >
              <div className="px-4 py-3">
                <span className="block text-sm font-semibold text-gray-900 dark:text-white">
                  {user?.user?.displayName || user?.user?.email?.split('@')[0]}
                </span>
                <span className="block truncate text-sm text-gray-500 dark:text-gray-400">{user?.user?.email}</span>
              </div>
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => dispatch(handleLogout())} className="text-gray-500 dark:text-gray-400">
                Sign out
              </Dropdown.Item>
            </Dropdown>
          </div>
        </div>
      </Navbar>
    </header>
  )
}
