import React, { CSSProperties, useEffect, useState } from 'react'
import { FaBars, FaCog } from 'react-icons/fa'

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Button } from './button'
import { CheckboxField, CheckboxGroup } from './checkbox'
import Drawer from './drawer'
import { Label } from './fieldset'

export function DataTableConfigure({
  table,
  columnVisibility,
  setColumnVisibility,
  columnOrder,
  setColumnOrder,
}: {
  table: any
  columnVisibility: Record<string, boolean>
  setColumnVisibility: (columnVisibility: Record<string, boolean>) => void
  columnOrder: string[]
  setColumnOrder: (columnOrder: string[]) => void
}) {
  const [open, setOpen] = useState(false)
  const [tempColumnVisibility, setTempColumnVisibility] = useState(columnVisibility)
  const [tempColumnOrder, setTempColumnOrder] = useState(columnOrder)

  const handleToggleVisibility = (columnId: string) => {
    setTempColumnVisibility((prev) => {
      const newColumnVisibility = { ...prev, [columnId]: prev[columnId] || prev[columnId] === undefined ? false : true }
      return newColumnVisibility
    })
  }

  const onApply = () => {
    setColumnVisibility(tempColumnVisibility)
    setColumnOrder(tempColumnOrder)
    setOpen(false)
  }

  const onReset = () => {
    setTempColumnVisibility({})
    setColumnVisibility({})
    setTempColumnOrder(columnOrder)
  }

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event
    if (active && over && active.id !== over.id) {
      setTempColumnOrder((_columnOrder) => {
        const oldIndex = _columnOrder.indexOf(active.id as string)
        const newIndex = _columnOrder.indexOf(over.id as string)
        return arrayMove(_columnOrder, oldIndex, newIndex) //this is just a splice util
      })
    }
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )

  useEffect(() => {
    setTempColumnVisibility(columnVisibility)
    setTempColumnOrder(columnOrder)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <>
      <Button onClick={() => setOpen(true)} color="white">
        <FaCog className="h-4 w-4" />
        Configure
      </Button>
      <Drawer
        open={open}
        setOpen={setOpen}
        title={'Configure'}
        className="z-50"
        body={
          <>
            <DndContext
              collisionDetection={closestCenter}
              modifiers={[restrictToVerticalAxis]}
              onDragEnd={handleDragEnd}
              sensors={sensors}
            >
              <span className="text-sm text-gray-600">Selected column will be shown in table</span>
              <CheckboxGroup className="mt-2">
                <SortableContext items={tempColumnOrder}>
                  {tempColumnOrder.map((key: string) => {
                    const col = table.getAllLeafColumns().find((col: any) => col.columnDef.id === key)
                    if (!col) return null
                    return (
                      <DraggableHeader
                        key={col.columnDef.id}
                        col={col}
                        checked={tempColumnVisibility[col.columnDef.id] !== false}
                        onChange={handleToggleVisibility.bind(null, col.columnDef.id)}
                      />
                    )
                  })}
                </SortableContext>
              </CheckboxGroup>
            </DndContext>
          </>
        }
        footer={
          <div className="flex w-full flex-row justify-start gap-4">
            <Button onClick={onApply}>Save as preference</Button>
            <Button outline onClick={onReset}>
              Reset
            </Button>
          </div>
        }
      />
    </>
  )
}

const DraggableHeader = ({
  col,
  checked,
  onChange,
}: {
  col: any
  checked: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}) => {
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id: col.columnDef.id,
  })
  const style: CSSProperties = {
    opacity: isDragging ? 0.8 : 1,
    position: 'relative',
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    transition,
    whiteSpace: 'nowrap',
    zIndex: isDragging ? 1 : 0,
  }

  return (
    <div ref={setNodeRef} style={style} key={col.id}>
      <div className="flex flex-row items-center gap-2">
        <button {...attributes} {...listeners}>
          <FaBars className="h-4 w-4 text-gray-400" />
        </button>
        <CheckboxField key={col?.headerName} className="p-1.5">
          <input
            {...{
              type: 'checkbox',
              checked,
              onChange,
              id: `configure-column-${col.columnDef.id}`,
              class: 'text-zinc-950 focus:!text-none ring-1 rounded-[3px]',
            }}
          />
          <Label htmlFor={`configure-column-${col.columnDef.id}`}>{col.columnDef.header}</Label>
        </CheckboxField>
      </div>
    </div>
  )
}
