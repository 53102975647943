import React, { ReactNode, useEffect, useRef } from 'react'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
  buttonRef: React.RefObject<HTMLButtonElement>
}

const ButtonModal: React.FC<ModalProps> = ({ isOpen, onClose, children, buttonRef }) => {
  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [onClose, buttonRef])

  if (!isOpen) return null

  return (
    <div
      ref={modalRef}
      className="absolute z-[10000] mt-2 w-96 rounded-lg border border-gray-200 bg-white p-4 shadow-lg"
      style={{ top: '100%', right: 0 }}
    >
      <button className="absolute right-2 top-2 text-gray-600 hover:text-gray-900" onClick={onClose}>
        &times;
      </button>
      {children}
    </div>
  )
}

export default ButtonModal
