import React from 'react'
import { BadgeButton, Colors } from './badge'

const statusColors = {
  unreceived: 'zinc',
  'partial ok': 'green',
  success: 'green',
  failure: 'red',
  discrepancy: 'red',
  failed: 'red',
  un_received: 'zinc',
  partial_ok: 'green',
  all_ok: 'green',
  error: 'red',
  mixed: 'amber',
  unvalidated: 'red'
}

const statusText = {
  unreceived: 'Unreceived',
  'partial ok': 'Partial OK',
  success: 'Success',
  failure: 'Failure',
  discrepancy: 'Discrepancy',
  failed: 'Failed',
  un_received: 'Unreceived',
  partial_ok: 'Partial OK',
  all_ok: 'All OK',
  in_progress: 'In progress',
  error: 'Discrepancy',
  mixed: 'Mixed',
  unvalidated: 'Unvalidated',
}

const Status = ({
  type,
  text,
  disabled,
  data = {},
  href,
  target,
  handleClick,
}: {
  type: 'Button' | 'Link'
  text: string
  disabled: boolean
  target: string
  data?: unknown
  href?: any
  handleClick?: (v: unknown) => any
}) => {
  return (
    <div className="">
      {type === 'Button' ? (
        <BadgeButton
          color={statusColors?.[text?.toLowerCase() as keyof typeof statusColors] as Colors}
          disabled={disabled}
          onClick={() => {
            handleClick?.(data as unknown)
          }}
        >
          {statusText[text?.toLowerCase() as keyof typeof statusColors]}
        </BadgeButton>
      ) : (
        !!href && (
          <BadgeButton
            color={statusColors?.[text?.toLowerCase() as keyof typeof statusColors] as Colors}
            disabled={disabled}
            to={href}
            target={target}
          >
            {statusText[text?.toLowerCase() as keyof typeof statusColors]}
          </BadgeButton>
        )
      )}
    </div>
  )
}

export default Status
