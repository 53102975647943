import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PaginatedQueryRequest } from '../firebase/model'
import { listenerForShipmentStats, shipmentsListener } from '../firebase/query/shipments'
import {
  updateLoader,
  updateShipment,
  updateShipmentStats,
  updateStatsLoading,
} from '../reduxStore/slices/shipments/shipmentsSlice'
import { AppDispatch } from '../reduxStore/store'

const useShipments = (request: PaginatedQueryRequest) => {
  const orgId = useSelector((state: any) => state.authReducer.orgId)
  const dispatch = useDispatch<AppDispatch>()
  useEffect(() => {
    dispatch(updateLoader())
    const shipments = shipmentsListener({
      ...request,
      orgId,
    }, (payload: any) => dispatch(updateShipment(payload)))
    return () => shipments()
  }, [dispatch, request, orgId])

  useEffect(() => {
    dispatch(updateStatsLoading())
    const shipmentsStats = listenerForShipmentStats({
      ...request,
      orgId,
    }, (payload: any) => dispatch(updateShipmentStats(payload)))
    return () => shipmentsStats()
  }, [dispatch, request, orgId])
}

export default useShipments
