import { TaskType } from 'shared/components/models/taskTypeMap'

export const taskDueDateOptions = 3

export const getDueDateColor = (dueDate: Date) => {
  const now = new Date()
  const due = new Date(dueDate)
  const diff = due.getTime() - now.getTime()
  const diffInHours = diff / (1000 * 60 * 60)
  const diffInDays = diffInHours / 24
  if (diffInHours < 0) {
    return 'text-red-700'
  } else if (diffInDays < taskDueDateOptions) {
    return 'text-yellow-500'
  } else {
    return 'tex-black'
  }
}

export const getDueDateText = (dueDate: Date, status: string) => {
  if (status === 'completed') return 'Completed'
  const now = new Date()
  const due = new Date(dueDate)
  const diff = due.getTime() - now.getTime()
  const diffInHours = diff / (1000 * 60 * 60)
  const diffInDays = diffInHours / 24
  if (diffInHours < 0) {
    return 'Overdue'
  } else if (diffInDays < taskDueDateOptions) {
    return 'Due Soon'
  } else {
    return 'Upcoming'
  }
}

export const getTaskStatus = (status: string) => {
  switch (status) {
    case 'assigned':
      return 'Open'
    case 'in_progress':
      return 'In Progress'
    case 'completed':
      return 'Completed'
    case 'error':
      return 'Error'
    case 'pending_action':
      return 'Pending Action'
    default:
      return 'Unknown'
  }
}

export const getTaskStatusColor = (status: string) => {
  switch (status) {
    case 'assigned':
      return 'text-gray-900'
    case 'in_progress':
      return 'text-yellow-500'
    case 'completed':
      return 'text-gray-400'
    case 'error':
      return 'text-red-700'
    case 'pending_action':
      return 'text-yellow-500'
    default:
      return 'text-black'
  }
}

export const getTaskTypeDisplayName = (type: string) => {
  return TaskType[type as keyof typeof TaskType] || type
}

export const getTaskTypeOptions = (): {
  value: string
  label: string
}[] => {
  return Object.keys(TaskType).map((key) => ({
    value: key,
    label: TaskType[key as keyof typeof TaskType],
  }))
}
