import { ActionCardType } from '../action-cards'

export enum DocumentType {
  master_bill_of_lading = 'MBL',
  house_bill_of_lading = 'HBL',
  carrier_arrival_notice = 'Carrier AN',
  ocean_arrival_notice = 'Ocean AN',
  master_air_waybill = 'MAWB',
  house_air_waybill = 'HAWB',
  air_arrival_notice = 'Air AN',
  booking_confirmation = 'BC',
  commercial_invoice = 'CI',
  packing_list = 'PL',
  isf = 'ISF',
  automated_manifest_system = 'AMS',
  pre_alert = 'Pre Alert',
  rail_arrival_notice = 'Rail AN',
  rail_arrival_notice_reminder = 'Rail AN Reminder',
  rail_pre_arrival_notice = 'Rail Pre AN',
  cargo_availability = 'Cargo Availability',
  ap_invoice = 'AP Invoice',
  osd_report = 'OSD Report',
  isf_hold_notice = 'ISF Hold Notice',
  release_order = 'Release Order',
  cbp_form_7512 = 'CBP 7512',
  canada_customs_response_report = 'CCRR',
  permit_to_transfer = 'PTT',
  emanifest = 'eManifest',
  freight_manifest = 'Freight Manifest',
  consolidation_manifest = 'Consol Manifest',
  exam_hold_release = 'Exam Hold Release',
  exam_hold_notice = 'Exam Hold Notice',
  freetime_notification = 'Freetime Notice',
  quotation = 'Quote',
  profit_lost_sharing_report = 'P/L Sharing Report',
  cfs_striping_list = 'CFS Report',
  power_of_attorney = 'POA',
  unknown = 'Unknown',
}

export const disabledStatus = ['in_progress', 'Not validated', 'Mixed']
export const successVarientStatus = ['partial_ok', 'success', 'all_ok']

export const shipmentStats: ActionCardType = [
  {
    id: 'needs_attention',
    title: 'Needs attention',
    value: 0,
    isActive: false,
    request: {
      key: 'validation_result.status_code',
      operation: 'in',
      value: [
        {
          label: 'Discrepancy',
          value: 'error',
        },
      ],
    },
  },
  {
    id: 'missing_documents',
    title: 'Missing documents',
    value: 0,
    isActive: false,
  },
  {
    id: 'in_progress',
    title: 'In progress',
    value: 0,
    isActive: false,
    request: {
      key: 'validation_result.status_code',
      operation: 'in',
      value: [
        {
          label: 'Partial OK',
          value: 'partial_ok',
        },
        {
          label: 'Discrepancy',
          value: 'error',
        },
      ],
    },
  },
  {
    id: 'completed',
    title: 'Completed',
    value: 0,
    isActive: false,
    request: {
      key: 'validation_result.status_code',
      operation: 'in',
      value: [
        {
          label: 'All OK',
          value: 'all_ok',
        },
      ],
    },
  },
]
