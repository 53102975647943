import { collection, getDocs, query, where } from '@firebase/firestore'
import { Button, Radio, TextInput } from 'flowbite-react'
import React, { useCallback, useEffect, useState } from 'react'
import { CgArrowsExpandRight } from 'react-icons/cg'
import { HiSearch } from 'react-icons/hi'
import { RiErrorWarningFill } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Drawer from 'shared/components/drawer'
import EmailModalButton from 'shared/components/EmailModalButton'
import EmptyContent from 'shared/components/empty-content'
import { Label } from 'shared/components/fieldset'
import { LoadingIcon } from 'shared/components/loading-icon'
import { RadioField, RadioGroup } from 'shared/components/radio'
import { Spinner } from 'shared/components/Spinner'
import { db } from 'shared/firebase/config'
import useTaskDetails from 'shared/hooks/useTaskDetails'
import { DocumentSearch } from 'shared/icons/DocumentSearch'
import { DocumentSuccess } from 'shared/icons/DocumentSuccess'
import { taskService } from 'shared/services'
import { handleErrorResponse } from 'shared/services/utils'
import { twMerge } from 'tailwind-merge'
import DetailsHeader from './Components/DetailsHeader'

type AddressDocument = {
  id: string
  address?: {
    name?: string
    address_line_1?: string
    address_line_2?: string
    city?: string
    state?: string
    country_code?: string
    postal_code?: string
  }
  org_id: string
}

const TaskDetailsMissingOrgData: React.FC = () => {
  const { taskId } = useParams<{ taskId: string }>()
  const [refreshCount, setRefreshCount] = useState(0)
  const { task, loading, error } = useTaskDetails(taskId || '', refreshCount)
  const orgId = useSelector((state: any) => state.authReducer.orgId)

  const [addresses, setAddresses] = useState<any[]>([])
  const [selectedAddress, setSelectedAddress] = useState<any | null>(null) // Store the full selected address
  const [filteredAddresses, setFilteredAddresses] = useState<any[]>([])
  const [isFetchingAddresses, setFetchingAddresses] = useState(false)
  const [tempSelectedAddressId, setTempSelectedAddressId] = useState<string | null>(null) // Temporary state
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedAction, setSelectedAction] = useState<string | null>(null)

  const handleActionChange = (action: string | null) => {
    setSelectedAction(action)

    // Reset other state if necessary when switching actions
    if (action !== 'override') {
      setSelectedAddress(null)
    }
  }

  const [selectedOption, setSelectedOption] = useState('')
  const [isDrawerOpen, setDrawerOpen] = useState(false)

  const [searchTerm, setSearchTerm] = useState('')

  const [isSubmitting, setIsSubmitting] = useState(false) // State for loading

  const handleSubmit = async () => {
    if (!selectedAction || !taskId) return
    setIsSubmitting(true)
    setErrorMessage('')
    const payload = {
      id: taskId,
      version: task?.version,
      result:
        selectedAction === 'save_address'
          ? { save_address: true }
          : selectedAction === 'override' && selectedAddress
            ? { fm_address_id: selectedAddress.id }
            : {},
    }

    try {
      await taskService.resolveTask(payload)
      setRefreshCount((prev) => prev + 1) // Refresh the task details
    } catch (error) {
      setErrorMessage(handleErrorResponse(error))
    } finally {
      setIsSubmitting(false)
    }
  }

  const fetchAddresses = useCallback(async () => {
    if (!task?.org_id) return

    setFetchingAddresses(true)

    try {
      const q = query(collection(db, 'addresses'), where('org_id', '==', orgId))

      const querySnapshot = await getDocs(q)
      const fetchedAddresses = querySnapshot.docs
        .map(
          (doc) =>
            ({
              id: doc.id,
              ...doc.data(),
            }) as AddressDocument
        )
        .filter((addressDoc) => addressDoc.address?.name === task.data.party.name) // Filter by party name

      setAddresses(fetchedAddresses)
    } catch (error) {
      console.error('Error fetching addresses:', error)
    } finally {
      setFetchingAddresses(false)
    }
  }, [task, orgId])

  useEffect(() => {
    fetchAddresses()
  }, [fetchAddresses])

  useEffect(() => {
    if (task?.status === 'completed' && task?.result) {
      if (task.result.save_address) {
        setSelectedOption('upload')
        setSelectedAction('save_address')
      } else if (task.result.fm_address_id) {
        setSelectedOption('override')
        setSelectedAction('override')
        const matchingAddress = addresses.find((addr) => addr.id === task.result.fm_address_id)
        setSelectedAddress(matchingAddress || null)
      }
    }
  }, [task, addresses])

  useEffect(() => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase()
    const filtered = addresses.filter((addressDoc) => {
      const address = addressDoc.address
      return (
        address &&
        (address.address_line_1?.toLowerCase().includes(lowerCaseSearchTerm) ||
          address.address_line_2?.toLowerCase().includes(lowerCaseSearchTerm) ||
          address.city?.toLowerCase().includes(lowerCaseSearchTerm) ||
          address.state?.toLowerCase().includes(lowerCaseSearchTerm) ||
          address.country_code?.toLowerCase().includes(lowerCaseSearchTerm) ||
          address.postal_code?.toLowerCase().includes(lowerCaseSearchTerm))
      )
    })
    setFilteredAddresses(filtered)
  }, [searchTerm, addresses])

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(event.target.value)
  }

  const formatAddress = (address: any) => {
    if (!address) return []

    const {
      address_line_1 = '',
      address_line_2 = '',
      city = '',
      state = '',
      country_code = '',
      postal_code = '',
    } = address

    const country = address.country ? address.country : country_code

    // Return each line as a string
    return [`${address_line_1} ${address_line_2}`.trim(), `${city}, ${state}, ${country}, ${postal_code}`.trim()]
  }

  if (loading) return <Spinner />

  if (error) {
    return <EmptyContent title="Error" description={`Error: ${error.message}`} />
  }

  if (!task) {
    return <EmptyContent title="Task not found" className="py-8" />
  }

  return (
    <div className="flex h-screen flex-col">
      <DetailsHeader task={task} />
      <div className="height-screen-minus-navbar container m-6 mx-auto flex flex-col items-center justify-center">
        <div className="flex h-full w-full gap-6">
          <div className="flex flex-1 flex-col overflow-y-auto rounded-lg border border-gray-200 bg-white py-4">
            <div className="flex items-center gap-4 border-b px-6 pb-4">
              <div className="flex items-center gap-2">
                <DocumentSuccess />
                <span className="text-lg font-semibold text-gray-900">Main Document</span>
              </div>
              <div className="ml-auto">
                <EmailModalButton task={task} />
              </div>
              <a
                href={task.downloadUrl}
                className="rounded-lg border border-gray-200 bg-white p-2"
                target="_blank"
                rel="noreferrer"
              >
                <CgArrowsExpandRight />
              </a>
            </div>
            <div className="demo-image mt-4 w-full flex-1 px-4">
              <object
                data={task.downloadUrl}
                type="application/pdf"
                width="100%"
                height="100%"
                className="h-full w-full object-contain"
              >
                <p>
                  <a href={task.downloadUrl}>See PDF</a>
                </p>
              </object>
            </div>
          </div>
          <div className="flex-1 overflow-y-auto rounded-lg border border-gray-200 bg-white py-4">
            <div className="flex items-center justify-between gap-4 border-b px-6 pb-4">
              <div className="flex items-center gap-2">
                <DocumentSearch />
                <span className="text-lg font-semibold text-gray-900">Missing Record details</span>
              </div>
            </div>
            <div className="p-4">
              <div className="flex items-center gap-2 rounded-lg bg-red-50 p-4">
                <RiErrorWarningFill className="h-5 w-5 text-red-800" />
                <span className="font-semibold text-red-800">
                  The following record is missing in your system of record.
                </span>
              </div>
              <div className="mt-8 rounded-lg bg-[#F3F4F6] p-5">
                <p className="mb-2 text-lg font-bold text-gray-900">Issue Description</p>
                {task?.data?.party?.name && <p className="text-gray-500">Address missing for {task.data.party.name}</p>}

                <hr className="my-4" />
                <p className="mb-2 text-lg font-bold text-gray-900">Address Missing</p>
                {task?.data?.party && (
                  <div className="text-gray-500">
                    {formatAddress(task.data.party).map((line, index) => (
                      <p key={index}>{line}</p>
                    ))}
                  </div>
                )}

                <hr className="my-4" />
                <p className="mb-2 text-lg font-bold text-gray-900">Addresses on Record</p>
                {isFetchingAddresses ? (
                  <div className="flex w-full items-center justify-center">
                    <LoadingIcon className="h-8 w-8" />
                  </div>
                ) : addresses.length > 0 ? (
                  addresses.map((addressDoc, index) => {
                    const formattedAddress = formatAddress(addressDoc.address)
                    return (
                      <div key={index} className="mb-2 text-gray-500">
                        {formattedAddress?.map((line, idx) => <p key={idx}>{line}</p>)}
                        <hr className="my-2" />
                      </div>
                    )
                  })
                ) : (
                  <p className="text-gray-500">No addresses available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 mt-auto w-full border-t border-gray-200 bg-white p-4">
        <div className="container mx-auto">
          <div className="flex flex-col items-center justify-end gap-1">
            {errorMessage && <p className="w-full text-right text-red-500">{errorMessage}</p>}
            <div className="flex w-full flex-row items-center justify-end gap-4">
              <Button
                disabled={task.status === 'completed'}
                color="black"
                className={twMerge(['border', selectedOption === 'ignore' ? 'border-black' : 'border-gray-200'])}
              >
                <label className="flex cursor-pointer items-center gap-2">
                  <Radio
                    id="ignore-documents"
                    name="duplicate"
                    value="ignore"
                    checked={selectedOption === 'ignore'}
                    onChange={(e) => {
                      handleRadioChange(e)
                      handleActionChange(null) // Reset action as ignore doesn't trigger submission
                    }}
                    disabled={task.status === 'completed'}
                  />
                  <span>Ignore This Document</span>
                </label>
              </Button>

              <Button
                disabled={task.status === 'completed'}
                color="black"
                className={twMerge(['border', selectedOption === 'save_address' ? 'border-black' : 'border-gray-200'])}
              >
                <label className="flex cursor-pointer items-center gap-2">
                  <Radio
                    id="upload-fixed"
                    name="duplicate"
                    value="upload"
                    checked={selectedOption === 'upload'}
                    onChange={(e) => {
                      handleRadioChange(e)
                      handleActionChange('save_address') // Set save_address as the action
                    }}
                    disabled={task.status === 'completed'}
                  />
                  <span>I’ve fixed issue, upload this document</span>
                </label>
              </Button>

              <Button
                disabled={task.status === 'completed'}
                color="black"
                className={twMerge([
                  'border',
                  selectedOption === 'override' ? 'border-black' : 'border-gray-200',
                  task.status === 'completed' ? 'cursor-not-allowed' : '',
                ])}
                onClick={() => {
                  setDrawerOpen(true) // Open the drawer
                  setSelectedOption('override') // Set selected option
                }}
              >
                <label className="flex cursor-pointer items-center gap-2">
                  <Radio
                    id="override-address"
                    name="duplicate"
                    value="override"
                    checked={selectedOption === 'override'}
                    onChange={(e) => {
                      handleRadioChange(e)
                      handleActionChange('override') // Set override as the action
                    }}
                    disabled={task.status === 'completed'}
                    onClick={(e) => e.stopPropagation()} // Prevent triggering button's onClick
                  />
                  <div className="flex items-center gap-4">
                    <div>
                      <p className="text-left">Override the missing address</p>
                      {selectedAddress && (
                        <p className="mt-1 w-48 truncate text-left text-xs text-gray-500">
                          {selectedAddress?.address && (
                            <>
                              {formatAddress(selectedAddress.address).map((line, index) => (
                                <p key={index}>{line}</p>
                              ))}
                            </>
                          )}
                        </p>
                      )}
                    </div>
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      {/* Icon */}
                    </svg>
                  </div>
                </label>
              </Button>

              <Drawer
                open={isDrawerOpen}
                setOpen={setDrawerOpen}
                title={'Override the missing address'}
                body={
                  <>
                    <TextInput
                      icon={HiSearch}
                      id="search"
                      name="search"
                      placeholder="Search for missing location"
                      type="search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <p className="my-4 text-xs text-gray-600">Or select from below</p>
                    <div className="address-list">
                      {isFetchingAddresses ? (
                        <div className="flex w-full items-center justify-center">
                          <LoadingIcon className="h-8 w-8" />
                        </div>
                      ) : filteredAddresses.length > 0 ? (
                        <RadioGroup name="addresses" className="!mt-0 flex flex-col gap-y-4">
                          {filteredAddresses.map((addressDoc, index) => {
                            const address = addressDoc.address
                            return (
                              <RadioField key={index} className="!mt-0">
                                <Radio
                                  id={`address-${index}`}
                                  name="addresses"
                                  value={addressDoc.id}
                                  checked={tempSelectedAddressId === addressDoc.id} // Use temporary state for selection
                                  onChange={() => setTempSelectedAddressId(addressDoc.id)} // Update temporary state
                                />

                                <Label htmlFor={`address-${index}`}>
                                  {address && (
                                    <>
                                      {formatAddress(address).map((line, index) => (
                                        <p key={index} className={index === 1 ? 'text-xs text-gray-500' : 'mb-0'}>
                                          {line}
                                        </p>
                                      ))}
                                    </>
                                  )}
                                </Label>
                              </RadioField>
                            )
                          })}
                        </RadioGroup>
                      ) : (
                        <p className="text-gray-500">No addresses available</p>
                      )}
                    </div>
                  </>
                }
                footer={
                  <>
                    <button
                      className={twMerge([
                        'rounded-lg border border-gray-200 bg-black px-4 py-2 text-white',
                        tempSelectedAddressId ? '' : 'disabled:bg-gray-400',
                      ])}
                      disabled={!tempSelectedAddressId} // Enable only if an address is selected in the drawer
                      onClick={() => {
                        const selected = addresses.find((address) => address.id === tempSelectedAddressId) // Get the full address
                        setSelectedAddress(selected || null) // Save the full address to the state
                        handleActionChange('override') // Update the selected action
                        setDrawerOpen(false) // Close the drawer
                      }}
                    >
                      Confirm
                    </button>
                  </>
                }
              />
              <button
                className={twMerge([
                  'flex flex-row rounded-lg border border-gray-200 bg-black px-4 py-2 text-white',
                  'disabled:cursor-not-allowed disabled:bg-gray-400',
                ])}
                disabled={!selectedAction || isSubmitting || task.status === 'completed'}
                onClick={handleSubmit} // Submit the action
              >
                {isSubmitting && <LoadingIcon className="mr-2 h-5 w-5 fill-white text-gray-200" />}
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TaskDetailsMissingOrgData
