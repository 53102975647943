import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FetchDocumentDetails } from '../firebase/model'
import { fetchDocumentById } from '../firebase/query/document'
import { getDocument, updateGetDocumentLoader } from '../reduxStore/slices/document/documentDetailsSlice'
import { AppDispatch } from '../reduxStore/store'

const useDocumentDetails = (request: FetchDocumentDetails) => {
  const orgId = useSelector((state: any) => state.authReducer.orgId)
  const dispatch = useDispatch<AppDispatch>()
  const loadDocument = (payload: any) => dispatch(getDocument(payload))

  useEffect(() => {
    dispatch(updateGetDocumentLoader())
    const docDetailsListener = fetchDocumentById(
      {
        ...request,
        orgId,
      },
      loadDocument
    )
    return () => docDetailsListener()
    //TODO: request was a dependency but caused an infinite loop/rerender so I removed it. Dig deeper what is happening and why
  }, [dispatch, orgId])
}

export default useDocumentDetails
