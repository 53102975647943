import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ActionCards } from 'shared/components/action-cards'
import DataTable from 'shared/components/data-table'
import { InputFilter } from 'shared/components/InputFilter'
import { PageTitle } from 'shared/components/page-title'
import Status from 'shared/components/status'
import { PaginatedQueryRequest } from 'shared/firebase/model'
import useDocuments from 'shared/hooks/useDocuments'
import { getDocumentTypeDisplayName, getDocumentTypeOptions } from 'shared/services'
import { createPaginateQuery } from 'shared/utils/utils'
import { dropdownOperations, inputboxOperations, processingStatusOptions } from '../Shipments/model'
import { cardDetails } from './model'

export default function Shipments() {
  const [payload, setPayload] = useState<PaginatedQueryRequest>({
    pageSize: 10,
    filter: [],
    sort: {
      key: 'created_at',
      value: 'desc',
    },
    orgId: '',
  })

  const [filter, setFilter] = useState<any>({})

  const [columns, setColumns] = useState([
    {
      accessorFn: (row: any) => row?.metadata?.received_email_doc_filename || '',
      cell: (info: any) =>
        info.getValue() ? (
          <Link to={`/document/${info.row.original?.id}`} target="_blank" className="text-blue-400 underline">
            {info.getValue()}
          </Link>
        ) : (
          '--'
        ),
      header: 'File name',
      size: 200,
      initialSize: 200,
      colName: 'File name',
      id: 'name',
      key: 'metadata.received_email_doc_filename',
      filter: {
        component: InputFilter,
        element: 'textbox',
        operations: inputboxOperations,
        accessor: 'metadata.received_email_doc_filename',
      },
    },
    {
      accessorFn: (row: any) => row.type,
      id: 'type',
      cell: (info: any) => getDocumentTypeDisplayName(info.getValue()) || info.getValue(),
      header: 'Type',
      size: 100,
      initialSize: 100,
      colName: 'Type',
      key: 'type',
      filter: {
        component: InputFilter,
        element: 'dropdown',
        operations: dropdownOperations,
        options: getDocumentTypeOptions(),
        accessor: 'type',
      },
    },
    {
      accessorFn: (row: any) => {
        return row.processing_result?.status_code
      },
      header: 'Processing status',
      size: 200,
      initialSize: 200,
      cell: (info: any) => (
        <Status
          type="Button"
          text={info.getValue() as string}
          disabled={false}
          handleClick={(_) => {}}
          target={info.getValue() as string}
        />
      ),
      colName: 'Status',
      id: 'status',
      key: 'processing_result.status_code',
      filter: {
        component: InputFilter,
        element: 'dropdown',
        operations: dropdownOperations,
        options: processingStatusOptions,
        accessor: 'processing_result.status_code',
      },
    },
    {
      accessorFn: (row: any) => row?.created_at || '--',
      cell: (info: any) =>
        info.getValue() !== '--' ? moment(info.getValue()).format('MM/DD/YYYY HH:mm:ss') : info.getValue(),
      header: 'Processing timestamp',
      size: 250,
      initialSize: 250,
      key: 'created_at',
      id: 'timestamp',
    },
    // {
    //   accessorKey: 'validationStatus',
    //   header: 'Validation status',
    //   size: 200,
    //   initialSize: 200,
    //   cell: (info: any) => (
    //     <Status
    //       type="Button"
    //       text={info.getValue() as string}
    //       disabled={false}
    //       handleClick={(_) => {}}
    //       target={info.getValue() as string}
    //     />
    //   ),
    //   colName: 'Validation status',
    //   id: 'validationStatus',
    // },
  ])

  useDocuments(payload)

  const [cardData, setCardData] = useState(cardDetails)
  const docs = useSelector((state: any) => state.documentsReducer)

  useEffect(() => {
    const updatedCards = [...cardData]
    const {
      stats: { unreceived, processing_failed },
    } = docs
    updatedCards[0].value = processing_failed
    updatedCards[1].value = unreceived
    setCardData([...updatedCards])
  }, [docs.stats])

  const applyFilters = (filter: any) => {
    const req = createPaginateQuery(filter, payload)
    setPayload(req)
  }

  return (
    <main>
      <PageTitle title="Documents" />
      <div className="max-w-6xl">
        <ActionCards
          cards={cardData}
          loading={docs.stats.loading}
          filter={filter}
          filterKey="status"
          setCards={setCardData}
          setFilter={setFilter}
          applyFilters={applyFilters}
        />
      </div>
      <DataTable
        columns={columns}
        data={docs.documents}
        totalPages={docs.totalPages}
        firstToken={docs.firstToken}
        lastToken={docs.lastToken}
        loading={docs.isLoading}
        searchQuery={payload}
        filter={filter}
        totalCountText={`Total ${docs.totalData} documents`}
        setFilter={setFilter}
        setColumns={setColumns}
        setSearchQuery={setPayload}
        handleApply={applyFilters}
      />
    </main>
  )
}
