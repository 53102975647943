export type ValidationStatus = 'success' | 'discrepancy' | 'unvalidated' | 'overridden'

export interface Validation {
  validation_type: string
  field_values: FieldValue[]
  field_name: string
  resolved?: boolean
  resolved_value?: string
  status: ValidationStatus
}

export interface FieldValue {
  value: string
  shipping_document_ids: string[]
}

export interface ShipmentDetailsState {
  involvedParties: Array<InvolvedParty>
  containers: Array<Container>
  routes: Array<Routes>
}

export interface Routes {
  fieldName: string
  value: string
  validation: Validation
}

export const validationPassed = ['all_ok', 'partial_ok']

export interface InvolvedParty {
  partyName: string
  name: string
  email: string
  nameKey: string
  emailKey: string
}

export interface Container {
  containerNumber: string
  type: string
  cargoDescription: string
}

export const routesKeys: Array<{
  key: string
  accesorKey?: string
  appendKey?: string
  isTimestamp?: boolean
  fallBackValidationKey?: string
}> = [
  {
    key: 'automated_manifest_system_number',
    accesorKey: '',
  },
  {
    key: 'estimated_time_of_arrival',
    accesorKey: 'seconds',
    isTimestamp: true,
  },
  {
    key: 'estimated_time_of_departure',
    accesorKey: 'seconds',
    isTimestamp: true,
  },
  {
    key: 'house_bill_of_lading_number',
    accesorKey: '',
  },
  {
    key: 'house_bill_of_lading_release_type',
    accesorKey: '',
  },
  {
    key: 'incoterm',
    accesorKey: '',
  },
  {
    key: 'load_type',
    accesorKey: '',
  },
  {
    key: 'master_bill_of_lading_number',
    accesorKey: '',
  },
  {
    key: 'ocean_carrier',
  },
  {
    key: 'number_of_origin_bill_of_lading',
  },
  {
    key: 'port_of_discharge',
    accesorKey: 'unloc',
  },
  {
    key: 'place_of_delivery',
    accesorKey: 'unloc',
  },
  {
    key: 'port_of_loading',
    accesorKey: 'unloc',
  },
  {
    key: 'total_packages',
    accesorKey: 'value',
    appendKey: 'unit',
    fallBackValidationKey: 'total_packages',
  },
  {
    key: 'total_volume',
    accesorKey: 'value',
    appendKey: 'unit',
  },
  {
    key: 'total_weight',
    accesorKey: 'value',
    appendKey: 'unit',
  },
  {
    key: 'total_units',
    accesorKey: 'value',
    appendKey: 'unit',
  },
  {
    key: 'total_value',
    accesorKey: 'amount',
    appendKey: 'currency_code',
  },
  {
    key: 'vessel_name',
    accesorKey: '',
  },
  {
    key: 'vessel_voyage_number',
    accesorKey: '',
    fallBackValidationKey: 'voyage_number',
  },
]

export enum RouteFieldNames {
  'automated_manifest_system_number' = 'AMS #',
  'estimated_time_of_arrival' = 'ETA',
  'estimated_time_of_departure' = 'ETD',
  'house_bill_of_lading_number' = 'HBL #',
  'house_bill_of_lading_release_type' = 'Release type',
  'incoterm' = 'Incoterm',
  'load_type' = 'Mode',
  'master_bill_of_lading_number' = 'MBL #',
  'ocean_carrier' = 'Carrier',
  'number_of_origin_bill_of_lading' = 'Original BOL',
  'port_of_discharge' = 'POD',
  'port_of_loading' = 'POL',
  // 'total_package_count' = 'Inner Piece Count',
  total_packages = 'Inner Piece Count',
  'total_volume' = 'Volume',
  'total_weight' = 'Weight',
  'vessel_name' = 'Vessel',
  'vessel_voyage_number' = 'Voyage',
  'voyage_number' = 'Voyage',
  'total_value' = 'Value',
  'total_units' = 'Units',
}

export const rightSidePanelFormEnableStatus = ['success', 'discrepancy', 'unvalidated', 'overridden']
